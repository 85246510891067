import React from "react";
import {
	Modal,
	ModalDialog,
	ModalOverflow,
	Box,
	Typography,
	Button,
	Stack,
	Divider,
	Select,
	Alert,
	Option,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import folderList from "../folderList";
import { useForm, Controller } from "react-hook-form";
import { OpenInNew } from "@mui/icons-material";
import Helpers from "../../../../../utils/Helpers";

const folders = [...folderList().images, ...folderList().documents];

const FileViewer = ({
	optimizingFiles,
	uploadsInProgress,
	selectedFile,
	moveFile,
	handleSelectUrl,
	places,
	handleChangeParam,
}) => {
	const [sel, setSel] = React.useState(selectedFile?.folder);
	const [move, setMove] = React.useState(false);
	const { t } = useTranslation();
	const handleMove = () => {
		if (move) {
			setMove(false);
			moveFile(sel, selectedFile.id);
		} else {
			setMove(true);
		}
	};
	return (
		<>
			<Stack direction="column" alignItems="center">
				<Box
					sx={{
						overflow: "auto",
						textAlign: "center",
						p: 2,
					}}
				>
					{selectedFile.contentType.startsWith("image/") && (
						<img width="100%" src={selectedFile?.url} />
					)}
					<Typography sx={{ background: "#F1F1F1", p: 1 }}>
						{selectedFile.name}
					</Typography>
					<Typography
						sx={{ mt: 2 }}
						component="a"
						target="_blank"
						href={selectedFile?.url}
					>
						Afficher <OpenInNew />
					</Typography>
				</Box>
				<Box sx={{ width: "80%" }}>
					<Button
						fullWidth
						sx={{ mt: 2 }}
						onClick={() => {
							handleSelectUrl({
								full: selectedFile?.url,
								thumb: selectedFile?.thumbnail || null,
							});
						}}
					>
						Utiliser ce fichier
					</Button>{" "}
					{/*<Button
						fullWidth
						color={"neutral"}
						sx={{ mt: 2 }}
						onClick={handleChangeParam}
					>
						Quitter
					</Button>*/}
					<Box
						sx={{
							display: selectedFile?.notRemovable
								? "none"
								: "block",
							mt: move && 2,

							border: move && "1px solid #eee",
							p: move && 2,
							boxShadow: move && "0px 0px 10px #00000025",
							borderRadius: move && "10px",
						}}
					>
						{move && (
							<Select
								onChange={(e, v) => {
									setSel(v);
								}}
							>
								{folders.map((folder) => (
									<Option
										selected={sel === folder}
										key={folder}
										value={folder}
									>
										{t(folder)}
									</Option>
								))}
							</Select>
						)}
						<Button
							fullWidth
							color={move ? "primary" : "secondary"}
							sx={{ mt: 2 }}
							onClick={handleMove}
						>
							{!move ? t("move_file") : t("save")}
						</Button>
						{move && (
							<Button
								fullWidth
								color={"neutral"}
								sx={{ mt: 2 }}
								onClick={() => setMove(false)}
							>
								{t("cancel")}
							</Button>
						)}
					</Box>
				</Box>
			</Stack>
		</>
	);
};

export default FileViewer;
