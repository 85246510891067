import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { Hits } from "react-instantsearch";

import { Filters, AnimationItem } from "./partials";
import {
	RefinementList,
	Configure,
	Stats,
	RangeInput,
	Pagination,
} from "react-instantsearch";

import "instantsearch.css/themes/satellite.css"; // ou autre thème au choix
import { FilterContainer, AlgoliaContainer } from "Components/algolia";

const ListAnimations = ({ selected = [], onEnd }) => {
	const { t } = useTranslation();

	return (
		<>
			<AlgoliaContainer index="animations">
				<Configure hitsPerPage={20} />

				<Box sx={{ mb: 2 }}>
					<Filters />
				</Box>
				<Box
					sx={{
						".ais-Hits-list": {
							display: "flex",
							flexDirection: "row",
							flexWrap: "wrap",
							gap: 2,
						},
						".ais-Hits-item": {
							p: 2,
							borderRadius: 15,
							border: "none",
							background: "none",
							display: "block",
						},
					}}
				>
					<Hits
						hitComponent={({ hit }) => {
							return (
								<AnimationItem
									selected={selected.find(
										(s) => s.id === hit.objectID,
									)}
									onSelect={() => onEnd({ ...hit })}
									key={hit.objectID}
									{...hit}
								/>
							);
						}}
					/>
				</Box>
				<Box
					sx={{
						mt: 2,
						mb: 3,
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Pagination />
				</Box>
			</AlgoliaContainer>
		</>
	);
};

export default ListAnimations;
ListAnimations.propTypes = {};
ListAnimations.defaultProps = {};
