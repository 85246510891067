import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Modal, ModalOverflow, ModalDialog } from "@mui/joy";
import PropTypes from "prop-types";
import { ListTeamBuildings } from "forms/team_buildings";
import { useParams } from "react-router-dom";
import Project from "models/projects";

const ProjectEntity = new Project();

const TeamBuildingsModal = ({ open, close, datas, setDatas }) => {
	const { t } = useTranslation();
	const { projectID } = useParams();

	const end = (datas) => {
		// Crée une copie des données reçues avec un ID
		const receivedData = { ...datas, id: datas.objectID };
		delete receivedData.objectID;

		setDatas((prev) => {
			const selectedTeamBuildings = prev?.project_team_buildings || [];

			// Vérifie si l'élément existe déjà dans le tableau
			const exists = selectedTeamBuildings.some(
				(selected) => selected.id === receivedData.id,
			);

			// Crée le tableau mis à jour
			const updatedTeamBuildings = exists
				? selectedTeamBuildings.filter((s) => s.id !== receivedData.id) // Supprimer
				: [...selectedTeamBuildings, receivedData]; // Ajouter

			// Met à jour ProjectEntity avant de retourner l'état mis à jour
			ProjectEntity.project_team_buildings = updatedTeamBuildings;
			ProjectEntity.updateProject(projectID);

			console.log("Updated TeamBuildings:", updatedTeamBuildings); // Debug

			return { ...prev, project_team_buildings: updatedTeamBuildings };
		});
	};

	return (
		<Modal open={open} onClose={close}>
			<ModalOverflow>
				<ModalDialog sx={{ minWidth: "400px" }}>
					<ListTeamBuildings
						selected={datas?.project_team_buildings || []}
						onEnd={end}
					/>
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default TeamBuildingsModal;
TeamBuildingsModal.propTypes = {};
TeamBuildingsModal.defaultProps = {};
