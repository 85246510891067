import React from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  Switch,
  Input,
  Button,
  FormLabel,
  Sheet,
  ModalOverflow,
  FormControl,
  Textarea,
  FormHelperText,
  Modal,
  ModalDialog,
  DialogTitle,
  Box,
  DialogContent,
  Stack,
} from "@mui/joy";
import { Image } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AddSimpleImage } from "UI";

import useGlobalContainer from "../../../../stores/Global";

const RestaurationModal = ({ isOpen, onClose, onSave, defaultValues }) => {
  const { t } = useTranslation();

  const { register, control, handleSubmit, setValue, getValues, reset, watch } =
    useForm({
      defaultValues: defaultValues || {
        nom: "",
        description: "",
        image: null,
      },
    });

  React.useEffect(() => {
    reset({});

    if (isOpen && defaultValues) {
      reset(defaultValues);
    }
  }, [isOpen, defaultValues, reset]);

  const onSubmit = (data) => {
    onSave(data);
    console.log(data);
    onClose();
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          onClose();
          reset({});
        }}
      >
        <ModalOverflow>
          <ModalDialog size="lg" sx={{ width: "80%" }}>
            <DialogTitle>
              {defaultValues?.nom ? (
                <p>
                  {" "}
                  Espace de restauration:<i> {defaultValues.nom}</i>
                </p>
              ) : (
                <p>Nouvel espace de restauration</p>
              )}
            </DialogTitle>

            <Sheet>
              <form>
                <Stack spacing={2}>
                  <Box
                    sx={{
                      p: 2,
                      border: "1px solid #eee",
                      borderRadius: "10px",
                    }}
                  >
                    <Stack spacing={2}>
                      <FormControl variant="outlined">
                        <FormLabel>Nom de l'espace restauration</FormLabel>
                        <Input
                          {...register("nom")}
                          label="Nom de l'espace de restauration"
                        />
                      </FormControl>
                      <FormControl variant="outlined">
                        <FormLabel>Description</FormLabel>
                        <Textarea
                          minRows={5}
                          {...register("description")}
                          label="Description"
                        />
                      </FormControl>

                      <FormControl variant="outlined">
                        <FormLabel>Photo</FormLabel>
                        <Box sx={{ display: "flex", justifyContent: "left" }}>
                          <AddSimpleImage
                            style={{
                              maxWidth: "300px",
                              width: "100%",
                              height: "180px",
                              p: 2,
                              textAlign: "center",
                            }}
                            label={t("add_featured_image")}
                            icon={<Image />}
                            remove={() => setValue("image", null)}
                            action={(img) => setValue("image", img)}
                            image={watch("image")}
                          />
                        </Box>
                      </FormControl>
                    </Stack>
                  </Box>

                  <Button type="button" onClick={handleSubmit(onSubmit)}>
                    Sauvegarder
                  </Button>
                </Stack>
              </form>
            </Sheet>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
};

export default RestaurationModal;
