import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, Stack, IconButton } from "@mui/joy";
import { RemoveRedEye, Delete } from "@mui/icons-material";
import PropTypes from "prop-types";
import Projects from "models/projects";
import { Filters } from "forms/admin/projects";
import { set } from "date-fns";
import SimpleTable from "UI/Tables/SimpleTable/SimpleTable";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, Link } from "react-router-dom";
import Helpers from "utils/Helpers";

const TypographyStyle = {
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const ProjectInstance = new Projects();

const ProjectsList = (props) => {
  const history = useHistory();

  const { t } = useTranslation();
  const [projects, setProjects] = React.useState([]);
  const [lastVisible, setLastVisible] = React.useState(null);
  const [deletingProject, setdeletingProject] = React.useState([]);
  const [filter, setFilter] = React.useState({
    uid: null,
    status: "active",
  });
  const [loading, setLoading] = React.useState(false);
  const [noMoreData, setNoMoreData] = React.useState(false);

  const handleDelete = async (id) => {
    if (window.confirm(t("sure_delete_this_project"))) {
      try {
        setdeletingProject((prev) => [...prev, id]);
        await ProjectInstance.deleteProject(id);
      } catch (error) {
        console.error("Failed to delete project:", error);
      }
      setProjects((prev) => prev.filter((p) => p.id !== id));
      setdeletingProject((prev) => prev.filter((p) => p !== id));
    }
  };

  const fetchProjects = async (isInitialLoad = false) => {
    try {
      setLoading(true);

      const result = await ProjectInstance.filterProjects(
        filter,
        isInitialLoad ? null : lastVisible
      );

      if (isInitialLoad) {
        setProjects(result.documents);
        setLastVisible(result.lastVisible || null);
      } else {
        setProjects((prev) => [...prev, ...result.documents]);
        setLastVisible(result.lastVisible || null);
      }

      setNoMoreData(result.no_more_data);
    } catch (error) {
      console.error("Failed to fetch projects:", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchProjects(true);
  }, [filter]);

  const columns = [
    "project_name",
    "created_at",
    "modified_at",
    "user_id",
    "project_event_start_date",
    "Actions",
  ];
  const actions = [
    (d) => (
      <Stack direction="row" spacing={1}>
        <IconButton
          variant="outlined"
          color="primary"
          component={Link}
          to={`/projects/${d.id}/dashboard`}
          target={"_blank"}
        >
          <RemoveRedEye />
        </IconButton>
        <IconButton
          variant="outlined"
          color="danger"
          loading={deletingProject.includes(d.id)}
          disabled={deletingProject.includes(d.id)}
          onClick={() => handleDelete(d.id)}
        >
          <Delete />
        </IconButton>
      </Stack>
    ),
  ];
  const fields = {
    project_name: (v) => <Typography sx={TypographyStyle}>{v}</Typography>,
    created_at: (v) => (
      <Typography sx={TypographyStyle}>
        {Helpers.fbDate(v, "DD-MM-YYYY")}
      </Typography>
    ),
    modified_at: (v) => (
      <Typography sx={TypographyStyle}>
        {Helpers.fbDate(v, "DD-MM-YYYY")}
      </Typography>
    ),
    uid: (v) => (
      <Typography sx={TypographyStyle}>
        <Link target={"_blank"} to={`/admin/users/${v}`}>
          {v}
        </Link>
      </Typography>
    ),
    project_event_start_date: (v) => (
      <Typography sx={TypographyStyle}>
        {Helpers.fbDate(v, "DD-MM-YYYY")}
      </Typography>
    ),
  };

  return (
    <Box>
      <Filters loading={loading} filter={filter} setFilter={setFilter} />

      <Box id="ed" sx={{ height: "60vh", width: "100%", overflowY: "scroll" }}>
        <InfiniteScroll
          dataLength={projects.length}
          scrollableTarget="ed"
          next={fetchProjects}
          hasMore={!noMoreData}
          loader={<h4>{t("loading")}...</h4>}
        >
          <SimpleTable
            inputProps={{
              stickyHeader: true,
              stickyFooter: true,
              hoverRow: true,
              borderAxis: "both",
            }}
            style={{
              maxWidth: "100%",
              overflow: "auto",
              background: "white",
              borderRadius: "10px",
              mb: 2,
            }}
            head={columns}
            datas={projects}
            fields={fields}
            actions={actions}
          />{" "}
        </InfiniteScroll>
      </Box>
    </Box>
  );
};

export default ProjectsList;

ProjectsList.propTypes = {};
ProjectsList.defaultProps = {};
