import React, { useState, useEffect } from "react";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  collection,
  query,
  orderBy,
  where,
  addDoc,
  onSnapshot,
  doc,
  startAfter,
  limit,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { storage, db } from "utils/MyFirebase"; // Votre fichier de configuration Firebase
import {
  LinearProgress,
  Typography,
  Box,
  Modal,
  ModalOverflow,
  Stack,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Button,
  IconButton,
  ModalDialog,
  Grid,
  Container,
  ModalContent,
} from "@mui/joy";
import {
  Delete,
  InsertDriveFile,
  Add,
  OpenInNew,
  Close,
} from "@mui/icons-material";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import useAuthContainer from "stores/Auth";
import useGlobalContainer from "stores/Global";
import Resizer from "react-image-file-resizer";
import {
  MyFiles,
  GoogleDrive,
  FolderSelector,
  FolderTree,
  FileViewer,
  PlaceTree,
  ImportedPlaceTree,
} from "./partials";
import { useCollection } from "react-firebase-hooks/firestore";

import { v4 as uuidv4 } from "uuid";
import Place from "models/places";

const BATCH_SIZE = 25; // Nombre de documents à charger par lot
const COMPRESSION = 1920; // compression image

const MediaLibrary = () => {
  const fileInput = React.useRef(null);
  const { user, setMediaLibUrl, userAccount } = useAuthContainer.useContainer();
  const location = useLocation();
  const navigate = useHistory();
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [folderSelectorModal, setFolderSelectorModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState({});
  const [lastVisible, setLastVisible] = useState(null); // Nouvel état pour la dernière référence de document visible
  const [folderSearch, setFolderSearch] = useState(null); // Nouvel état pour la dernière référence de document visible
  const [placeSearch, setPlaceSearch] = useState(null); // Nouvel état pour la dernière référence de document visible
  const documentsQuery = query(
    collection(db, "places"),
    where("uid", "==", user?.uid ?? null),
  );
  const [documents, loading, error] = useCollection(documentsQuery);

  const [selectedFile, setSelectedFile] = useState(null);
  const [optimizingFiles, setOptimizingFiles] = useState({}); // Pour suivre les fichiers en cours d'optimisation après l'upload

  const [uploadsInProgress, setUploadsInProgress] = useState({}); // Uploads en cours
  const [paramValue, setParamValue] = useState("");
  const [additionalDatas, setAdditionalDatas] = useState({});
  const [accessiblePlaces, setAccessiblePlaces] = useState([]);

  useEffect(() => {
    if (
      userAccount?.accessiblePlacesMedias &&
      userAccount?.accessiblePlacesMedias.length > 0
    ) {
      const fetchAccessiblePlaces = async () => {
        try {
          const places = [];
          for (const placeId of userAccount?.accessiblePlacesMedias) {
            const placeInstance = new Place({});
            const place = await placeInstance.getPlace(placeId);
            places.push({
              id: placeId,
              nom: place.nom,
              images: [
                ...new Set([
                  ...place.images,
                  place?.featured_image && place.featured_image,
                ]),
              ].map((f, i) => ({
                url: f,
                notRemovable: true,
                name: `${place.nom}_photo_${i}`,
                contentType: "image/*",
              })),
            });
          }
          setAccessiblePlaces(places);
        } catch (error) {
          console.error("Failed to fetch accessible places:", error);
        }
      };

      fetchAccessiblePlaces();
    }
  }, [userAccount?.accessiblePlacesMedias]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        COMPRESSION,
        COMPRESSION,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
      );
    });

  const handleChangeParam = () => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.delete("media_lib");

    const newSearch = searchParams.toString();

    navigate.push(`${location.pathname}?${newSearch}`);
  };

  useEffect(() => {
    // Créez une instance de URLSearchParams à partir de la chaîne de recherche de l'URL
    const searchParams = new URLSearchParams(location.search);

    // Obtenez la valeur du paramètre que vous souhaitez suivre, par exemple 'monParam'
    const param = searchParams.get("media_lib"); // Remplacez 'monParam' par le nom réel de votre paramètre

    // Mettez à jour l'état de votre composant avec la nouvelle valeur du paramètre
    setParamValue(param);

    // Récupérez et décodez les valeurs du paramètre additionnalDatas
    const encodedAdditionalDatas = searchParams.get("additionnalDatas");
    if (encodedAdditionalDatas) {
      const decodedString = decodeURIComponent(encodedAdditionalDatas);
      const params = new URLSearchParams(decodedString);
      const additionalDatasObj = {};
      for (const [key, value] of params.entries()) {
        additionalDatasObj[key] = value;
      }
      setAdditionalDatas(additionalDatasObj);
    }
  }, [location]); // Ajoutez `location` comme dépendance pour que useEffect s'exécute à chaque fois que l'URL change

  useEffect(() => {
    if (user) {
      let q = query(
        collection(db, "uploads"),
        where("uid", "==", user.uid),
        orderBy("createdAt", "desc"),
        limit(BATCH_SIZE),
      );

      if (folderSearch) {
        q = query(q, where("folder", "==", folderSearch));
      }
      if (placeSearch) {
        q = query(q, where("place", "==", placeSearch));
      }

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const filesData = [];
        const optimizing = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();

          // Ajouter tous les fichiers dans filesData, mais ajouter seulement les images sans miniature à optimizing
          if (data.contentType.startsWith("image/") && !data.thumbnail) {
            optimizing.push({ id: doc.id, ...data });
          }
          // Inclure tous les autres fichiers, y compris les PDFs
          filesData.push({ id: doc.id, ...data });
        });

        setFiles(filesData); // Tous les fichiers, y compris les PDF
        setOptimizingFiles(optimizing); // Fichiers d'image en cours d'optimisation
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      });

      return () => unsubscribe();
    }
  }, [user, folderSearch, placeSearch]);

  const moveFile = async (folder, id) => {
    const docRef = doc(db, "uploads", id);
    await setDoc(docRef, { folder }, { merge: true });
  };

  const handleLoadMore = () => {
    // Charger plus de documents à partir de la dernière position visible
    let next = query(
      collection(db, "uploads"),
      where("uid", "==", user.uid),
      orderBy("createdAt", "desc"),
      startAfter(lastVisible),
      limit(BATCH_SIZE),
    );
    if (folderSearch) {
      next = query(next, where("folder", "==", folderSearch));
    }
    if (placeSearch) {
      next = query(next, where("place", "==", placeSearch));
    }

    onSnapshot(next, (querySnapshot) => {
      const newFiles = [];
      querySnapshot.forEach((doc) => {
        newFiles.push({ id: doc.id, ...doc.data() });
      });
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    });
  };

  const saveFile = (folder, place = null) => {
    // Récupérez tous les fichiers sélectionnés
    const selectedFiles = fileInput.current.files;
    if (!selectedFiles.length) return;

    Array.from(selectedFiles).forEach(async (file) => {
      const resized = file;
      /* const resized = file.type.startsWith("image/")
        ? await resizeFile(file)
        : file;*/
      const storageRef = ref(storage, `uploads/${user.uid}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, resized);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadsInProgress((prevState) => ({
            ...prevState,
            [file.name]: { progress, isComplete: false },
          }));
        },
        (error) => {
          console.error("Upload error", error);
          setUploadsInProgress((prevState) => {
            const { [file.name]: _, ...rest } = prevState;
            return rest;
          });
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const metadata = {
            url: downloadURL,
            contentType: file.type,
            folder,
            place,
            name: uploadTask.snapshot.ref.name,
            uid: user.uid,
            createdAt: new Date(),
            ...additionalDatas,
          };

          const docRef = doc(collection(db, "uploads"));
          await setDoc(docRef, metadata);

          if (file.type.startsWith("image/")) {
            setOptimizingFiles((prevState) => ({
              ...prevState,
              [file.name]: true,
            }));

            onSnapshot(docRef, (snapshot) => {
              const data = snapshot.data();
              if (data && data.thumbnail) {
                setOptimizingFiles((prevState) => {
                  const { [file.name]: _, ...rest } = prevState;
                  return rest;
                });
                setUploadsInProgress((prevState) => {
                  const { [file.name]: _, ...rest } = prevState;
                  return rest;
                });
              }
            });
          } else {
            setUploadsInProgress((prevState) => {
              const { [file.name]: _, ...rest } = prevState;
              return rest;
            });
          }
        },
      );
    });
  };

  const handleFileChange = () => {
    setFolderSelectorModal(true);
  };

  const handleDelete = async (file) => {
    try {
      const fileRef = ref(storage, file.url);
      await deleteObject(fileRef);
    } catch (e) {
      console.log(e);
    }
    await deleteDoc(doc(db, "uploads", file.id));
    setSelectedFile(null);
  };

  const handleSelectUrl = (url) => {
    setMediaLibUrl(url);
    handleChangeParam();
  };

  return user ? (
    <Modal open={Boolean(paramValue)} onClose={handleChangeParam}>
      <ModalOverflow>
        <ModalDialog layout="fullscreen">
          <IconButton
            sx={{
              position: "absolute",
              right: "33px",
              top: "16px",
            }}
            onClick={handleChangeParam}
          >
            <Close />
          </IconButton>
          <Typography level="h3" sx={{ textAlign: "center" }}>
            Bibliothèque de média
          </Typography>
          <Grid container>
            <Grid
              sx={{
                borderRight: "1px solid #888 ",
                position: "relative",
                height: "90vh",
                overflow: "auto",
              }}
              md={2}
            >
              <FolderTree
                setFolderSearch={setFolderSearch}
                folderSearch={folderSearch}
              />
              <ImportedPlaceTree
                setFiles={setFiles}
                places={accessiblePlaces}
              />
            </Grid>
            <Grid
              md={7}
              sx={{
                p: 2,
                height: "100vh",
                overflow: "auto",
              }}
            >
              <input
                style={{ display: "none" }}
                ref={fileInput}
                multiple
                type="file"
                onChange={handleFileChange}
              />
              <div>
                {Object.entries(uploadsInProgress).map(
                  ([fileName, { progress }]) => (
                    <Box sx={{ width: "200px" }} key={fileName}>
                      <p>
                        {/* Afficher "Optimisation en cours" si l'upload est terminé mais la miniature est en attente */}
                        {optimizingFiles[fileName]
                          ? `Optimisation en cours pour ${fileName}`
                          : `Envoi en cours ${fileName}: ${progress.toFixed(
                              2,
                            )}%`}
                      </p>
                      <LinearProgress
                        determinate
                        value={optimizingFiles[fileName] ? 100 : progress}
                      />
                    </Box>
                  ),
                )}

                <Tabs aria-label="Basic tabs" defaultValue={0}>
                  <TabList>
                    <Tab>Mes Images/Documents</Tab>
                    <Tab disabled={true} title="Bientôt disponible">
                      Base média Weevup
                    </Tab>
                  </TabList>
                  <TabPanel value={0}>
                    <PlaceTree
                      setPlaceSearch={setPlaceSearch}
                      placeSearch={placeSearch}
                      places={documents?.docs}
                    />
                    <MyFiles
                      fileInput={fileInput}
                      files={files}
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      uploading={uploading}
                      handleDelete={handleDelete}
                      handleLoadMore={handleLoadMore}
                    />{" "}
                  </TabPanel>
                  {/* <TabPanel value={1}>
                    <GoogleDrive
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      folder_id="0AENx_gOoVBirUk9PVA"
                    />
                  </TabPanel>*/}
                </Tabs>
              </div>
            </Grid>
            <Grid
              md={3}
              sx={{
                borderLeft: "1px solid #888 ",
                position: "relative",
                height: "90vh",
                overflow: "auto",
              }}
            >
              {selectedFile && (
                <FileViewer
                  optimizingFiles={optimizingFiles}
                  uploadsInProgress={uploadsInProgress}
                  handleSelectUrl={handleSelectUrl}
                  moveFile={moveFile}
                  handleChangeParam={handleChangeParam}
                  selectedFile={selectedFile}
                  places={documents?.docs}
                />
              )}
            </Grid>
          </Grid>
          <FolderSelector
            folderSearch={folderSearch}
            placeSearch={placeSearch}
            selectedFolder={selectedFolder}
            setSelectFolder={setSelectedFolder}
            open={folderSelectorModal}
            selectedPlace={selectedPlace}
            setSelectedPlace={setSelectedPlace}
            upload={saveFile}
            places={documents?.docs}
            file={fileInput?.current?.files[0]}
            close={() => setFolderSelectorModal(false)}
          />
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  ) : null;
};

export default MediaLibrary;
