import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, IconButton } from "@mui/joy";
import PropTypes from "prop-types";
import { FavoriteBorder, Favorite } from "@mui/icons-material";
import Project from "models/projects";
const ProjectEntity = new Project();
const cleanObject = (obj) => {
	return Object.fromEntries(
		Object.entries(obj).map(([key, value]) => [
			key,
			value === undefined ? null : value,
		]),
	);
};

const AddToFavorite = ({
	id,
	featured_image,
	nom,
	projectId,
	iconButton = true,
	ville,
	pays,
	style,
	region,
	type_lieu,
	code_postal,
	onEnd,
	projectPlaces = [], // Défaut à un tableau vide
}) => {
	const { t } = useTranslation();

	// Vérifier si l'élément est déjà dans les favoris
	const isAlreadyFavorite = React.useMemo(() => {
		return (
			Array.isArray(projectPlaces) &&
			projectPlaces.some((place) => place.id === id)
		);
	}, [projectPlaces, id]);

	const [isFav, setIsFav] = React.useState(isAlreadyFavorite);

	const rawFavObj = {
		id,
		featured_image,
		nom,
		ville,
		pays,
		region,
		type_lieu,
		code_postal,
	};

	// Nettoyage de l'objet pour éviter les champs undefined
	const favObj = cleanObject(rawFavObj);

	const handleAddToFavorites = () => {
		const updatedIsFav = !isFav;
		setIsFav(updatedIsFav);

		// Assurez-vous que projectPlaces est un tableau avant d'utiliser l'opérateur d'étalement
		const safeProjectPlaces = Array.isArray(projectPlaces)
			? projectPlaces
			: [];

		// Mettre à jour le tableau projectPlaces
		const updatedProjectPlaces = updatedIsFav
			? [...safeProjectPlaces, favObj] // Ajouter le favori
			: safeProjectPlaces.filter((place) => place.id !== id); // Retirer le favori

		// Assurez-vous que project_places est un tableau avant de l'affecter
		if (!Array.isArray(ProjectEntity.project_places)) {
			ProjectEntity.project_places = [];
		}

		// Mettre à jour ProjectEntity
		ProjectEntity.project_places = updatedProjectPlaces;

		try {
			ProjectEntity.updateProject(projectId); // Mise à jour dans Firestore
		} catch (error) {
			console.error("Erreur lors de la mise à jour du projet :", error);
		}

		// Appeler la fonction onEnd avec le nouvel état et les données
		if (onEnd) {
			onEnd(updatedProjectPlaces);
		}
	};

	const Component = iconButton ? IconButton : Button;

	return (
		<Box
			sx={{
				...style,
				display: "flex",
				justifyContent: { xs: "center" },
			}}
		>
			<Component
				variant={iconButton ? null : !isFav ? "outlined" : "solid"}
				onClick={handleAddToFavorites}
				sx={(theme) => ({
					".MuiSvgIcon-root": {
						color:
							iconButton && !isFav
								? "default"
								: theme.vars.palette.primary[500],
					},
				})}
			>
				{isFav ? <Favorite /> : <FavoriteBorder />}
				{iconButton
					? null
					: !isFav
					? t("add_to_favorite")
					: t("added_to_favorite")}
			</Component>
		</Box>
	);
};

export default AddToFavorite;
