import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	Modal,
	ModalDialog,
	Typography,
	Button,
	FormControl,
	FormLabel,
	Input,
} from "@mui/joy";
import PropTypes from "prop-types";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { arrayUnion } from "firebase/firestore";
import { storage } from "utils/MyFirebase";
import useAuthContainer from "stores/Auth";
import Project from "models/projects";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const ProjectInstance = new Project();

const AddDocumentModal = ({ open, handleClose, setDatas, datas }) => {
	const { t } = useTranslation();
	const { projectID } = useParams();

	const {
		handleSubmit,
		control,
		reset,
		setValue,
		formState: { isSubmitting },
	} = useForm();
	const { user } = useAuthContainer.useContainer();
	const onSubmit = async (data) => {
		try {
			// Récupérer le fichier
			const file = data.file_label[0];
			const documentName = data.document_label;

			if (!file || !documentName) {
				throw new Error(t("missingFieldsError"));
			}

			// Référencement Cloud Storage
			const storagePath = `documents/${user.uid}/${documentName}`;
			const fileRef = ref(storage, storagePath);

			// Upload du fichier
			await uploadBytes(fileRef, file);
			const url = await getDownloadURL(fileRef);

			// Afficher ou traiter l'URL
			console.log("URL du fichier :", url);
			const documentDatas = {
				id: uuidv4(),
				name: documentName,
				fileUrl: url,
			};
			ProjectInstance.project_documents = arrayUnion(documentDatas);

			await ProjectInstance.updateProject(projectID);
			setDatas({
				project_documents: [
					...(datas?.project_documents || []),
					documentDatas,
				],
			});
			// Réinitialiser le formulaire
			reset();
			handleClose();

			alert(t("uploadSuccess"));
		} catch (error) {
			console.error("Erreur lors de l'upload :", error);
			alert(t("uploadError"));
		}
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<ModalDialog
				aria-labelledby="AddDocumentModal-title"
				aria-describedby="AddDocumentModal-description"
				sx={{ maxWidth: 500 }}
			>
				<Typography
					id="AddDocumentModal-title"
					level="h4"
					component="h2"
				>
					{t("modalTitle")}
				</Typography>
				<Typography id="AddDocumentModal-description" sx={{ mt: 1 }}>
					{t("modalDescription")}
				</Typography>

				<form onSubmit={handleSubmit(onSubmit)}>
					<FormControl sx={{ mt: 2 }}>
						<FormLabel>{t("fileLabel")}</FormLabel>
						<Controller
							name="file_label"
							control={control}
							defaultValue={null}
							render={({ field }) => (
								<Input
									type="file"
									onChange={(e) =>
										setValue("file_label", e.target.files)
									}
									inputRef={field.ref}
								/>
							)}
						/>
					</FormControl>

					<FormControl sx={{ mt: 2 }}>
						<FormLabel>{t("documentNameLabel")}</FormLabel>
						<Controller
							name="document_label"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<Input
									type="text"
									placeholder={t("documentNamePlaceholder")}
									{...field}
								/>
							)}
						/>
					</FormControl>

					<Button
						type="submit"
						loading={isSubmitting}
						sx={{ mt: 2 }}
						fullWidth
					>
						{t("upload")}
					</Button>
				</form>

				<Button onClick={handleClose} sx={{ mt: 2 }}>
					{t("close")}
				</Button>
			</ModalDialog>
		</Modal>
	);
};

AddDocumentModal.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
};

AddDocumentModal.defaultProps = {
	open: false,
	handleClose: () => {},
	userId: "",
};

export default AddDocumentModal;
