import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, IconButton } from "@mui/joy";
import { Delete } from "@mui/icons-material";
import { Add } from "@mui/icons-material";
import PropTypes from "prop-types";
import Project from "models/projects";
import { OptionItemCard, OptionModal } from "UI";

const ProjectEntity = new Project();

const AnimationsList = ({ openSearch, setDatas, datas, project_id }) => {
	const { t } = useTranslation();
	const animations = datas?.project_animations || [];
	const [displayDetails, setDisplayDetails] = React.useState(null);

	const HandleDelete = (id) => {
		setDatas((prev) => {
			const newDatas = prev?.project_animations?.filter(
				(d) => d.id !== id,
			);
			ProjectEntity.project_animations = newDatas;
			ProjectEntity.updateProject(project_id);
			return { ...prev, project_animations: newDatas };
		});
	};
	return (
		<>
			<Button onClick={openSearch}>{t("add")}</Button>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					gap: 3,
					flexWrap: "wrap",
					mt: 2,
				}}
			>
				{animations.map((animation) => (
					<OptionItemCard
						id={animation.id}
						title={animation?.animation_name}
						description={animation?.animation_description}
						extraInfo={animation?.animation_price}
						image={animation?.animation_featured_image}
						onDelete={(id) => HandleDelete(animation.id)}
						showDetails={() => setDisplayDetails(animation)}
					/>
				))}
			</Box>
			<OptionModal
				open={Boolean(displayDetails)}
				onClose={() => setDisplayDetails(false)}
				title={displayDetails?.animation_name}
				description={displayDetails?.animation_description}
				price={displayDetails?.animation_price}
				image={displayDetails?.animation_featured_image}
			/>
		</>
	);
};

export default AnimationsList;
AnimationsList.propTypes = {};
AnimationsList.defaultProps = {};
