import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import {
  ProjectDates,
  ProjectEventTypeSeminar,
  ProjectPax,
  ProjectDetails,
  ProjectEventObjective,
  ProjectName,
  ProjectEventType,
  ProjectBudget,
} from "./partials";
import { useForm } from "react-hook-form";
import { FormSeparator } from "UI";
import Helpers from "utils/Helpers";
import Project from "models/projects";
import useAuthContainer from "stores/Auth";

const ProjectEntity = new Project();

const ProjectBriefForm = ({ mode = "creation", datas, onEnd }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, userAccount } = useAuthContainer.useContainer();
  const [creating, setCreating] = React.useState(false);
  const {
    control,
    setValue,
    getValues,
    register,
    watch,
    handleSubmit,
    formState: { error },
  } = useForm({ defaultValues: mode === "edition" ? { ...datas } : {} });

  console.log(datas);
  const save = async (d) => {
    setCreating(true);

    const formatProjectDate = (date) =>
      date ? Helpers.formatToDate(date) : date;

    const eventDatas = {
      ...d,

      status: "waiting",
      created_at: new Date(),
      project_event_end_date: formatProjectDate(d.project_event_end_date),
      project_event_start_date: formatProjectDate(d.project_event_start_date),
    };
    if (mode === "creation") {
      eventDatas.uid = user.uid;
    }

    const ProjectEntity = new Project(eventDatas);

    try {
      if (mode === "edition") {
        const editedProject = await ProjectEntity.updateProject(datas?.id);
        if (editedProject) {
          console.log("editedProject", editedProject);
          console.log("projectDatas", eventDatas);
          onEnd &&
            onEnd({
              ...eventDatas,
              project_event_end_date: d.project_event_end_date,
              project_event_start_date: d.project_event_start_date,
            });
        }
      } else {
        const createdProject = await ProjectEntity.addProject();
        if (createdProject) {
          history.push(`/projects/${createdProject.id}/dashboard`);
        }
      }
    } catch (e) {
      console.error(e.message);
    } finally {
      setCreating(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(save)}>
      <Box>
        <Typography
          sx={{
            textAlign: "center",
            m: 2,
          }}
          component="h1"
          level="h3"
        >
          {t("project_brief")}
        </Typography>
      </Box>
      <ProjectName register={register} error={error} />
      <FormSeparator title="project_date_form_create">
        <ProjectDates
          setValue={setValue}
          control={control}
          getValues={getValues}
        />
      </FormSeparator>
      <FormSeparator title="project_ProjectPax">
        <ProjectPax register={register} error={error} />
      </FormSeparator>
      <FormSeparator>
        <ProjectBudget control={control} setValue={setValue} watch={watch} />
      </FormSeparator>

      <ProjectEventType
        register={register}
        error={error}
        setValue={setValue}
        selected={getValues("project_event_type")}
      />
      <ProjectEventTypeSeminar
        setValue={setValue}
        watch={watch}
        error={error}
        register={register}
        selected={getValues("project_event_seminar_type")}
      />
      <ProjectEventObjective
        getValues={getValues}
        register={register}
        watch={watch}
        setValue={setValue}
        selectItem={getValues("project_event_objective")}
      />
      <FormSeparator title="project_details">
        <ProjectDetails register={register} />
      </FormSeparator>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {mode === "creation" && (
          <Button component={Link} to="/projects" color="neutral">
            {t("cancel")}
          </Button>
        )}
        <Button
          loading={creating}
          disabled={creating}
          type="submit"
          color="primary"
        >
          {mode === "creation"
            ? t("create_new_project")
            : t("edit_new_project")}
        </Button>
      </Box>
    </form>
  );
};

export default ProjectBriefForm;
ProjectBriefForm.propTypes = {};
ProjectBriefForm.defaultProps = {};
