import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Switch,
  Input,
  Button,
  FormLabel,
  Sheet,
  ModalOverflow,
  FormControl,
  Modal,
  ModalDialog,
  Textarea,
  DialogTitle,
  Box,
  Stack,
  Checkbox,
  Typography,
} from "@mui/joy";
import { Image } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { AddSimpleImage } from "UI";
import bedTypes from "datas/places/places_bed_type.json";

const BedRoomModal = ({ isOpen, onClose, onSave, defaultValues }) => {
  const { t } = useTranslation();

  const normalizeBedType = (bedType) => {
    if (Array.isArray(bedType)) {
      // Si c'est un tableau, le convertir en objet avec des valeurs par défaut
      return bedType.reduce((acc, label) => {
        acc[label] = { selected: true, qty: 1 };
        return acc;
      }, {});
    } else if (typeof bedType === "object" && bedType !== null) {
      // Si c'est déjà un objet, nettoyer les clés non conformes
      return Object.entries(bedType).reduce((acc, [key, value]) => {
        if (
          value &&
          typeof value === "object" &&
          value.selected !== undefined
        ) {
          acc[key] = value;
        }
        return acc;
      }, {});
    }
    // Sinon, retourner un objet vide
    return {};
  };
  const { register, control, handleSubmit, setValue, reset, watch } = useForm({
    defaultValues: defaultValues
      ? { ...defaultValues, bed_type: normalizeBedType(defaultValues.bed_type) }
      : {
          nom: "",
          description: "",
          nbr_pers: "",
          size: "",
          qty_available: "",
          image: null,
          bed_type: {},
        },
  });

  const image = watch("image");

  React.useEffect(() => {
    if (isOpen) {
      reset({
        ...defaultValues,
        bed_type: normalizeBedType(defaultValues?.bed_type),
      });
    }
  }, [isOpen, defaultValues, reset]);
  const onSubmit = (data) => {
    const normalizedData = {
      ...data,
      bed_type: normalizeBedType(data.bed_type),
    };
    onSave(normalizedData);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
        reset({});
      }}
    >
      <ModalOverflow>
        <ModalDialog size="lg" sx={{ width: "80%" }}>
          <DialogTitle>
            {defaultValues?.nom ? (
              <p>
                {t("bedroom")}: <i>{defaultValues.nom}</i>
              </p>
            ) : (
              <p>{t("new_bedroom")}</p>
            )}
          </DialogTitle>

          <Sheet>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={2}>
                <Box
                  sx={{
                    p: 2,
                    border: "1px solid #eee",
                    borderRadius: "10px",
                  }}
                >
                  <Stack spacing={2}>
                    <FormControl variant="outlined">
                      <FormLabel>{t("bed_room_name")}</FormLabel>
                      <Input {...register("nom")} />
                    </FormControl>

                    <FormControl variant="outlined">
                      <FormLabel>{t("bed_room_desc")}</FormLabel>
                      <Textarea {...register("description")} minRows={4} />
                    </FormControl>

                    <FormControl variant="outlined">
                      <FormLabel>{t("nbr_pers_room")}</FormLabel>
                      <Input type="number" {...register("nbr_pers")} />
                    </FormControl>

                    <FormControl variant="outlined">
                      <FormLabel>{t("size_room")}</FormLabel>
                      <Input type="number" {...register("size")} />
                    </FormControl>

                    <FormControl variant="outlined">
                      <FormLabel>{t("qty_available_room")}</FormLabel>
                      <Input type="number" {...register("qty_available")} />
                    </FormControl>

                    <FormControl variant="outlined">
                      <FormLabel>{t("bed_type")}</FormLabel>
                      <Stack spacing={1}>
                        {bedTypes.map((type) => (
                          <Controller
                            key={type.label}
                            name={`bed_type.${type.label}`}
                            control={control}
                            render={({ field }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  mb: 2,
                                }}
                              >
                                <Checkbox
                                  checked={!!field.value?.selected}
                                  onChange={(e) => {
                                    const selected = e.target.checked;
                                    const newValue = {
                                      ...field.value,
                                      selected,
                                      qty: selected ? field.value?.qty || 1 : 0,
                                    };
                                    field.onChange(newValue);
                                  }}
                                />
                                <Typography sx={{ ml: 1 }}>
                                  {t(type.label)}
                                </Typography>
                                {field.value?.selected && (
                                  <Input
                                    sx={{ ml: 2, width: "80px" }}
                                    type="number"
                                    inputProps={{ min: 1 }}
                                    value={field.value?.qty || ""}
                                    onChange={(e) => {
                                      const qty = parseInt(e.target.value, 10);
                                      field.onChange({
                                        ...field.value,
                                        qty,
                                      });
                                    }}
                                  />
                                )}
                              </Box>
                            )}
                          />
                        ))}
                      </Stack>
                    </FormControl>

                    <FormControl variant="outlined">
                      <FormLabel>{t("bed_room_pic")}</FormLabel>
                      <Box sx={{ display: "flex", justifyContent: "left" }}>
                        <AddSimpleImage
                          style={{
                            maxWidth: "300px",
                            width: "100%",
                            height: "180px",
                            p: 2,
                            textAlign: "center",
                          }}
                          label={t("add_featured_image")}
                          icon={<Image />}
                          remove={() => setValue("image", null)}
                          action={(img) => setValue("image", img)}
                          image={image}
                        />
                      </Box>
                    </FormControl>
                  </Stack>
                </Box>

                <Button type="button" onClick={handleSubmit(onSubmit)}>
                  Sauvegarder
                </Button>
              </Stack>
            </form>
          </Sheet>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default BedRoomModal;
