import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Typography, Chip, Button } from "@mui/joy";
import PropTypes from "prop-types";
import Project from "models/projects";

const statusList = ["waiting", "active", "canceled"];
const ProjectEntity = new Project();

const ProjectChangeStatusForm = ({ id, onEnd, status }) => {
	const { t } = useTranslation();
	const [updating, setUpdating] = React.useState(false);
	const { handleSubmit, control } = useForm({
		defaultValues: {
			status: status || "waiting", // Statut par défaut
		},
	});

	const handleFormSubmit = async (data) => {
		setUpdating(true);
		try {
			ProjectEntity.status = data.status;
			const saved = await ProjectEntity.updateProject(id);

			if (onEnd) {
				onEnd(data);
			}
		} catch (e) {
			console.error("error updating project status", e.message);
		}
		setUpdating(false);
	};

	return (
		<Box
			component="form"
			onSubmit={handleSubmit(handleFormSubmit)}
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 2,
				width: "100%",
				maxWidth: 400,
			}}
		>
			<Typography level="h5" sx={{ mb: 2 }}>
				{t("Change Project Status")}
			</Typography>

			<Controller
				name="status"
				control={control}
				render={({ field: { value, onChange } }) => (
					<Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
						{statusList.map((option) => (
							<Chip
								key={option}
								variant={
									value === option ? "solid" : "outlined"
								}
								color={value === option ? "primary" : "neutral"}
								onClick={() => onChange(option)}
								sx={{ cursor: "pointer" }}
							>
								{t(option)}
							</Chip>
						))}
					</Box>
				)}
			/>

			<Button
				loading={updating}
				disabled={updating}
				type="submit"
				variant="solid"
				color="primary"
				sx={{ mt: 2 }}
			>
				{t("save")}
			</Button>
		</Box>
	);
};

export default ProjectChangeStatusForm;

ProjectChangeStatusForm.propTypes = {
	onSubmit: PropTypes.func,
};

ProjectChangeStatusForm.defaultProps = {
	onSubmit: (data) => console.log(data),
};
