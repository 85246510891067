import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack } from "@mui/joy";
import PropTypes from "prop-types";
import Event from "models/events";
import { Footer } from "./partials";
import {
	BudgetEvent,
	EventDate,
	EventType,
	EventName,
	EventSeminarType,
	ParticipantQty,
	EventObjective,
} from "../commons";

import Helpers from "utils/Helpers";
import { useForm } from "react-hook-form";
import useAuthContainer from "stores/Auth";
import { useHistory } from "react-router-dom";

const CreateEventForm = (props) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { user } = useAuthContainer.useContainer();
	const [creating, setCreating] = React.useState(false);

	const {
		handleSubmit,
		formState: { errors },
		register,
		watch,
		control,
		setValue,
		getValues,
	} = useForm();

	const save = async (d) => {
		setCreating(true);
		const eventDatas = {
			...d,
			active_modules_global: ["home"],
			uid: user.uid,
			active: true,
			created_at: new Date(),
		};

		/*Convert moment date object to js date object*/
		if (eventDatas.event_end_date) {
			eventDatas.event_end_date = Helpers.formatToDate(
				eventDatas.event_end_date,
			);
		}
		if (eventDatas.event_start_date) {
			eventDatas.event_start_date = Helpers.formatToDate(
				eventDatas.event_start_date,
			);
		}

		// Create firestore document
		const EventEntity = new Event(eventDatas);

		try {
			const createdEvent = await EventEntity.addEvent();

			// redirect to event edit page
			if (createdEvent) {
				history.push(`/event/${createdEvent.id}/general`);
			}
		} catch (e) {
			console.log(e.message);
		}
		setCreating(false);
	};

	return (
		<>
			<form onSubmit={handleSubmit(save)}>
				<Stack>
					<EventName register={register} error={errors?.event_name} />
					{/*<EventType register={register} setValue={setValue} />
					<EventSeminarType
						watch={watch}
						register={register}
						setValue={setValue}
					/>
					<EventObjective
						register={register}
						watch={watch}
						setValue={setValue}
						getValues={getValues}
					/>
					<ParticipantQty
						register={register}
						error={errors?.event_participants_qty}
					/>*/}
					{/*<EventDate
						watch={watch}
						register={register}
						control={control}
						setValue={setValue}
						getValues={getValues}
					/>
					<BudgetEvent
						control={control}
						setValue={setValue}
						watch={watch}
					/>*/}
				</Stack>
				<Footer
					loading={creating}
					cancelAction={() => history.push("/event/templates")}
				/>
			</form>
		</>
	);
};

export default CreateEventForm;
CreateEventForm.propTypes = {};
CreateEventForm.defaultProps = {};
