import React from "react";
import PartnersRoutes from "./PartnersRoutes";
import { BOLayout } from "Components/layouts";
import { Tabs, TabList, Tab, TabPanel } from "@mui/joy";
import { useHistory, useLocation } from "react-router-dom";

const menuItems = [
	{ label: "Lieux", path: "/partners/places" },
	{ label: "Animation", path: "/partners/animation" },
	{ label: "Team Building", path: "/partners/team-building" },
];

const PartnersMenu = () => {
	const location = useLocation();
	const history = useHistory();

	// Détermine l'onglet actif en fonction de l'URL actuelle
	const currentTab = menuItems.findIndex(
		(item) => location.pathname === item.path,
	);

	const handleTabChange = (event, newValue) => {
		history.push(menuItems[newValue].path);
	};

	return (
		<Tabs
			value={currentTab !== -1 ? currentTab : 0}
			onChange={handleTabChange}
			aria-label="Navigation des partenaires"
		>
			<TabList>
				{menuItems.map((item, index) => (
					<Tab key={item.path} value={index}>
						{item.label}
					</Tab>
				))}
			</TabList>
			{menuItems.map((item, index) => (
				<TabPanel key={item.path} value={index}>
					{/* Contenu spécifique à chaque onglet */}
				</TabPanel>
			))}
		</Tabs>
	);
};

const Partners = (props) => {
	return (
		<>
			<BOLayout title="our_partners">
				<PartnersMenu />
				<PartnersRoutes />
			</BOLayout>
		</>
	);
};

export default Partners;
