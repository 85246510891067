import React from "react";
import { Switch, Route } from "react-router-dom";
import { NewProject, ListEvent, ProjectDashboard } from "./views";

const ProjectRoutes = () => {
	return (
		<Switch>
			<Route exact path="/projects">
				<ListEvent />
			</Route>
			<Route exact path="/projects/:projectID/dashboard">
				<ProjectDashboard />
			</Route>

			<Route path="/projects/create">
				<NewProject />
			</Route>
		</Switch>
	);
};

export default ProjectRoutes;
