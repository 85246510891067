import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { WhiteContainer } from "UI";
import ActionModal from "./ActionModal";
import { PlaceSearch } from "containers/places/views";
import Place from "models/places";

const placeInstance = new Place();

const Places = (props) => {
	const { t } = useTranslation();
	const [selected, setSelected] = React.useState(null);
	const [openActionModal, setOpenActionModal] = React.useState(false);
	const [openDetailModal, setOpenDetailModal] = React.useState(false);
	const [loadingPlace, setLoadingPlace] = React.useState(false);

	const HandleSelectAnimation = async (d) => {
		setLoadingPlace(true);
		setSelected(null);
		setOpenActionModal(true);

		if (d) {
			const place = await placeInstance.getPlace(d);
			if (place) {
				setSelected(place);
			}
		}
		setLoadingPlace(false);
	};

	return (
		<Box sx={{ background: "white", p: 1 }}>
			<PlaceSearch onEnd={HandleSelectAnimation} fromCatalog />
			<ActionModal
				loadingPlace={loadingPlace}
				{...selected}
				selected={selected}
				open={openActionModal}
				handleClose={() => setOpenActionModal(false)}
			/>
		</Box>
	);
};

export default Places;
Places.propTypes = {};
Places.defaultProps = {};
