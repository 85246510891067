import React from "react";
import { useTranslation } from "react-i18next";
import {
	Modal,
	ModalDialog,
	Typography,
	Button,
	Box,
	Divider,
	CircularProgress,
} from "@mui/joy";
import PropTypes from "prop-types";
import { AutocompleteSimple } from "UI";
import Project from "models/projects";
import { useForm, Controller } from "react-hook-form";
import useAuthContainer from "stores/Auth";

const MyModal = ({
	open,
	loadingPlace,
	handleClose,
	selected,
	id: placeID,
	featured_image,
	nom,
	animation_description,
	objectID,
	animation_featured_image,
}) => {
	const { t } = useTranslation();
	const { user } = useAuthContainer.useContainer();
	const { control, handleSubmit, setValue, getValues, watch, reset } =
		useForm();
	const [project, setProject] = React.useState(null);
	const projectInstance = new Project();
	const [optionIsInProject, setOptionIsInProject] = React.useState(false);
	const [saved, setSaved] = React.useState(false);
	const [saving, setSaving] = React.useState(false);

	const projectId = watch("project");

	React.useEffect(() => {
		reset();
		setProject(null);
		setSaved(false);
		setOptionIsInProject(null);
	}, [objectID, open]);

	const searchProjectByName = async (searchTerm) => {
		if (!searchTerm) return [];
		const result = await projectInstance.searchProjectByName(
			searchTerm,
			user.uid,
		);
		return result.map((project) => ({
			label: `${project.project_name}`,
			value: project.id,
		}));
	};

	const fetchProject = async (id) => {
		const project = await projectInstance.getProject(id);
		setProject(project);
		setOptionIsInProject((prev) =>
			project?.project_places?.find(
				(p) => (p?.id || p?.objectID) === placeID,
			),
		);
	};

	const handleAddToProject = async (d) => {
		setSaving(true);
		try {
			if (d?.project) {
				projectInstance.project_places = [
					...(project?.project_places || []),
					{
						...selected,
						featured_image:
							featured_image?.thumb ||
							featured_image?.full ||
							featured_image,
					},
				];
				await projectInstance.updateProject(d.project);
				setSaved(true);
			}
		} catch (e) {
			console.log(e.error);
		}
		setSaving(false);
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<ModalDialog
				aria-labelledby="MyModal-title"
				aria-describedby="MyModal-description"
				sx={{ maxWidth: 500 }}
			>
				{loadingPlace ? (
					<CircularProgress />
				) : (
					<Box>
						<Box
							sx={{
								height: 200,
								width: "auto",
								background: `url("${
									featured_image?.full || featured_image
								}") center center / cover`,
							}}
						></Box>
						<Typography
							id="MyModal-title"
							level="h4"
							component="h2"
						>
							{nom}
						</Typography>
						<Typography id="MyModal-description" sx={{ mt: 1 }}>
							{animation_description}
						</Typography>

						<Divider sx={{ m: 1 }} />

						<Box>
							<Typography>{t("add_to_project")}</Typography>
							<form onSubmit={handleSubmit(handleAddToProject)}>
								<Controller
									name="project"
									control={control}
									render={({ field }) => (
										<AutocompleteSimple
											placeholder={t("select_a_project")}
											label="Select Project"
											fetchOptions={searchProjectByName}
											onSelect={(value) => {
												if (value) {
													// Vérifie que value n'est pas null
													fetchProject(value.value);
													setValue(
														"project",
														value.value,
													);
													setValue(
														"name",
														value.label,
													);
												} else {
													// Réinitialisez les valeurs si aucun projet n'est sélectionné
													setValue("project", "	");
													setValue("name", "");
												}
											}}
										/>
									)}
								/>{" "}
								{projectId && !optionIsInProject && !saved && (
									<Button
										disabled={saving}
										loading={saving}
										type="submit"
										color="primary"
										sx={{ mt: 2 }}
									>
										{t("add_place_to_selected_project")}
									</Button>
								)}
								{optionIsInProject && (
									<Typography color="warning">
										{t("place_already_in_project")}
									</Typography>
								)}
								{saved && (
									<Typography color="success">
										{t("place_saved_project")}
									</Typography>
								)}
							</form>
						</Box>

						<Button
							type="button"
							onClick={handleClose}
							color="neutral"
							sx={{ mt: 2 }}
						>
							{t("close")}
						</Button>
					</Box>
				)}
			</ModalDialog>
		</Modal>
	);
};

MyModal.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
};

MyModal.defaultProps = {
	open: false,
	handleClose: () => {},
};

export default MyModal;
