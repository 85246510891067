import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, IconButton, Tooltip } from "@mui/joy";
import {
	Delete,
	Settings,
	Cancel,
	CheckCircle,
	HourglassTop,
	Domain,
	SportsTennis,
	Diversity3,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import Project from "models/projects";
import useAuthContainer from "stores/Auth";
import Helpers from "utils/Helpers";
import { useHistory } from "react-router-dom";

const ProjectEntity = new Project();

const statusIcons = {
	waiting: <HourglassTop color="warning" />,
	active: <CheckCircle color="primary" />,
	canceled: <Cancel color="danger" />,
};

const formatDate = (d) => Helpers.fbDate(d, "DD-MM-YYYY");
const listStyle = {
	display: "flex",
	alignItems: "center",
	display: "flex",
	flexDirection: "row",
	columnGap: "5px",
};

const ProjectCard = ({
	project_name,
	project_places,
	project_animations,
	project_team_buildings,
	status,
	id,
	deleteProject,
	created_at,
	t,
	toProject,
}) => (
	<Box
		sx={{
			border: "1px solid #eee",
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			width: "320px",
			rowGap: "9px",

			border: "1px solid #888",
			borderRadius: "7px",
		}}
	>
		<Box sx={{ p: 1 }}>
			<Typography sx={{ fontSize: "11px" }}>
				{t("created_at")} : {formatDate(created_at)}
			</Typography>
			<Box
				sx={{
					pt: 1,
					display: "flex",
					flexDirection: "row",
					columnGap: "10px",
				}}
			>
				<Box>
					<Tooltip title={t(status)}>{statusIcons[status]}</Tooltip>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						rowGap: "5px",
					}}
				>
					<Typography sx={{ fontWeight: "bold", mb: 1 }}>
						{project_name}
					</Typography>
					<Typography sx={listStyle}>
						<Domain color="primary" />
						{t("x_places_in_project", {
							nbr: project_places?.length || 0,
						})}
					</Typography>
					<Typography sx={listStyle}>
						<SportsTennis color="primary" />
						{t("x_animations_in_project", {
							nbr: project_animations?.length || 0,
						})}
					</Typography>
					<Typography sx={listStyle}>
						<Diversity3 color="primary" />
						{t("x_team_buildings_in_project", {
							nbr: project_team_buildings?.length || 0,
						})}
					</Typography>
				</Box>
			</Box>
		</Box>
		<Box
			sx={{
				borderTop: "1px solid #888",
				display: "flex",
				flexDirection: "row",
				p: 1,
				columnGap: 1,
				justifyContent: "end",
			}}
		>
			<IconButton onClick={toProject} variant="outlined">
				<Settings />
			</IconButton>

			<IconButton variant="outlined" onClick={deleteProject}>
				<Delete />
			</IconButton>
		</Box>
	</Box>
);

const Content = (props) => {
	const { t } = useTranslation();
	const { user } = useAuthContainer.useContainer();
	const [datas, setDatas] = React.useState([]);

	const history = useHistory();
	const fetchProject = async () => {
		try {
			const projects = await ProjectEntity.getProjectsByUid(user?.uid);
			setDatas(projects);
		} catch (e) {
			console.log("no projects to fetch --> ", e.message);
		}
	};

	const deleteProject = async (id) => {
		if (window.confirm(t("sure_delete_project"))) {
			try {
				await ProjectEntity.deleteProject(id);

				setDatas((prev) => {
					return prev.filter((p) => p.id !== id);
				});
			} catch (e) {
				console.error("error deleting project", e.message);
			}
		}
	};

	React.useEffect(() => {
		if (user?.uid) {
			fetchProject();
		}
	}, [user]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				gap: 3,
				flexWrap: "wrap",
			}}
		>
			{datas &&
				datas.map((project) => (
					<ProjectCard
						t={t}
						key={project.id}
						{...project}
						deleteProject={() => deleteProject(project.id)}
						toProject={() =>
							history.push(`/projects/${project.id}/dashboard`)
						}
					/>
				))}
		</Box>
	);
};

export default Content;
Content.propTypes = {};
Content.defaultProps = {};
