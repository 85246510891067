import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Modal, ModalDialog, ModalOverflow } from "@mui/joy";
import PropTypes from "prop-types";
import { EditAnimationForm } from "forms/admin/animations";

const CreateAnimationModal = ({ open, close, datas }) => {
	const { t } = useTranslation();

	return (
		<Modal onClose={close} open={open}>
			<ModalOverflow>
				<ModalDialog sx={{ width: "700px" }}>
					<EditAnimationForm
						onEnd={close}
						datas={datas}
						mode={datas ? "edition" : "creation"}
					/>
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default CreateAnimationModal;
CreateAnimationModal.propTypes = {};
CreateAnimationModal.defaultProps = {};
