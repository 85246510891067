import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import PlaceItem from "./PlaceItem";
import Project from "models/projects";
import { useParams } from "react-router-dom";

const ProjectEntity = new Project();

const SelectedPlaces = ({ places = [], setDatas }) => {
	console.log(places);
	const { projectID } = useParams();
	const { t } = useTranslation();
	const removePlace = (id) => {
		const newPlaces = places.filter((p) => p.id !== id);

		ProjectEntity.project_places = newPlaces;
		ProjectEntity.updateProject(projectID);
		setDatas({ project_places: newPlaces });
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				rowGap: 2,
				//				maxHeight: "452px",
				//overflow: "scroll",
			}}
		>
			{places &&
				places?.length > 0 &&
				places.map((place) => (
					<PlaceItem
						removePlace={() => removePlace(place.id)}
						key={place.id}
						{...place}
					/>
				))}
		</Box>
	);
};

export default SelectedPlaces;
SelectedPlaces.propTypes = {};
SelectedPlaces.defaultProps = {};
