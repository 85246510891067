import React from "react";
import { useTranslation } from "react-i18next";
import {
	IconButton,
	Box,
	Typography,
	Modal,
	ModalOverflow,
	ModalDialog,
} from "@mui/joy";
import { Close } from "@mui/icons-material";
import PropTypes from "prop-types";
import { ProjectAddPlaceForm } from "forms/projects";
import { useParams } from "react-router-dom";

const AddAProposition = ({ open, close, datas, setDatas }) => {
	const { t } = useTranslation();
	const { projectID } = useParams();

	const end = (dat) => {
		setDatas({ project_places: dat });
	};
	return (
		<Modal open={open} onClose={close}>
			<ModalOverflow>
				<ModalDialog sx={{ minWidth: "100%" }}>
					<IconButton
						variant={"outlined"}
						sx={{ textAlign: "right" }}
						onClick={close}
					>
						<Close />
						{t("close")}
					</IconButton>
					<ProjectAddPlaceForm
						projectId={projectID}
						onEnd={end}
						places={datas?.project_places || []}
					/>
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default AddAProposition;
AddAProposition.propTypes = {};
AddAProposition.defaultProps = {};
