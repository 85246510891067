import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, Stack, IconButton } from "@mui/joy";
import { Add, Edit, Delete, Upload } from "@mui/icons-material";
import PropTypes from "prop-types";
import { CreateAnimationModal } from "./partials";
import { WhiteContainer } from "UI";
import SimpleTable from "UI/Tables/SimpleTable/SimpleTable";
import Animation from "models/animations";

const AnimationEntity = new Animation();
const AnimationsList = (props) => {
	const { t } = useTranslation();
	const [selectedDatas, setSelectedDatas] = React.useState(null);
	const [datas, setDatas] = React.useState(null);
	const [openModal, setOpenModal] = React.useState(false);

	const fetchAnimations = async () => {
		try {
			await AnimationEntity.getAnimations([], (d) => {
				setDatas(d);
			});
		} catch (e) {
			console.log("failed fetch animations", e.message);
		}
	};

	React.useEffect(() => {
		fetchAnimations();
	}, []);

	const deleteAnimation = (id) => {
		AnimationEntity.deleteAnimation(id);
	};

	const handleFileUpload = async (event) => {
		const file = event.target.files[0];
		if (!file) return;

		try {
			const fileContent = await file.text();
			let jsonData = JSON.parse(fileContent);

			// Nettoyage des hashtags et conversion en tableau
			jsonData = jsonData.map((item) => {
				if (item.animation_hashtags) {
					item.animation_hashtags = item.animation_hashtags
						.split(/[, ]+/) // Séparer par virgules ou espaces
						.map((tag) => tag.replace(/^#/, "").trim()) // Retirer le #
						.filter((tag) => tag.length > 0); // Exclure les entrées vides
				} else {
					item.animation_hashtags = [];
				}
				return item;
			});

			// Importer chaque élément dans Firebase
			for (const item of jsonData) {
				const animation = new Animation(item);
				await animation.addAnimation();
			}

			alert("Importation réussie !");
			fetchAnimations(); // Rafraîchir la liste
		} catch (error) {
			console.error("Erreur d'importation :", error);
			alert("Erreur lors de l'importation du fichier JSON.");
		}
	};

	const TypographyStyle = {
		maxWidth: "100%",
		overflow: "hidden",
		textOverflow: "ellipsis",
	};

	const columns = [
		t("animation_name"),
		t("animation_type"),
		t("animation_hashtags"),
		"",
	];
	const actions = [
		(d) => (
			<Stack direction="row" spacing={1}>
				<IconButton
					variant="outlined"
					color="primary"
					onClick={() => setSelectedDatas(d)}
				>
					<Edit />
				</IconButton>
				<IconButton
					variant="outlined"
					color="primary"
					onClick={() => deleteAnimation(d.id)}
				>
					<Delete />
				</IconButton>
			</Stack>
		),
	];

	const fields = {
		animation_name: (v) => (
			<Typography sx={TypographyStyle}>{v}</Typography>
		),
		animation_type: (v) => (
			<Typography sx={TypographyStyle}>{t(v)}</Typography>
		),
		animation_hashtags: (v) => (
			<Typography sx={TypographyStyle}>#{v?.join(", #")}</Typography>
		),
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
			<Box>
				<Stack direction="row" spacing={2}>
					<Button color="primary" onClick={() => setOpenModal(true)}>
						<Stack direction="row" spacing={1}>
							<Add />
							<Typography sx={{ color: "white" }}>
								{t("create_new_animation")}
							</Typography>
						</Stack>
					</Button>
					<Button component="label" color="secondary">
						<Stack direction="row" spacing={1}>
							<Upload />
							<Typography sx={{ color: "white" }}>
								{t("import_json")}
							</Typography>
						</Stack>
						<input
							type="file"
							accept="application/json"
							hidden
							onChange={handleFileUpload}
						/>
					</Button>
				</Stack>
			</Box>

			<WhiteContainer>
				<SimpleTable
					inputProps={{
						stickyHeader: true,
						stickyFooter: true,
						hoverRow: true,
						borderAxis: "both",
					}}
					style={{
						maxWidth: "100%",
						overflow: "auto",
						background: "white",
						borderRadius: "10px",
						mb: 2,
					}}
					head={columns}
					datas={datas}
					fields={fields}
					actions={actions}
				/>
			</WhiteContainer>
			<CreateAnimationModal
				datas={selectedDatas}
				open={Boolean(selectedDatas) || openModal}
				close={() => {
					setSelectedDatas(null);
					setOpenModal(false);
				}}
			/>
		</Box>
	);
};

export default AnimationsList;
AnimationsList.propTypes = {};
AnimationsList.defaultProps = {};
