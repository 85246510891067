import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { ListAnimations } from "forms/animations";
import { WhiteContainer } from "UI";
import ActionModal from "./ActionModal";
const Animations = (props) => {
	const { t } = useTranslation();
	const [selected, setSelected] = React.useState(null);
	const [openActionModal, setOpenActionModal] = React.useState(false);
	const [openDetailModal, setOpenDetailModal] = React.useState(false);

	const HandleSelectAnimation = (d) => {
		setSelected(d);
		console.log(d);
		setOpenActionModal(true);
	};

	return (
		<Box sx={{ background: "white", p: 1 }}>
			<ListAnimations onEnd={HandleSelectAnimation} />
			<ActionModal
				{...selected}
				selected={selected}
				open={openActionModal && selected}
				handleClose={() => setOpenActionModal(false)}
			/>
		</Box>
	);
};

export default Animations;
Animations.propTypes = {};
Animations.defaultProps = {};
