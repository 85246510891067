import React from "react";
import { BOLayout } from "../../../../components/layouts";
import { Header, Content } from "./partials";
import { WhiteContainer } from "../../../../components/UI";
import { useParams } from "react-router-dom";

const ListEvent = (props) => {
	let { eventID } = useParams();
	return (
		<BOLayout isDocumentStatusMonitoring title="my_projects">
			<Header />
			<WhiteContainer>
				<Content />
			</WhiteContainer>
		</BOLayout>
	);
};

export default ListEvent;
