import React from "react";
import ProjectRoutes from "./ProjectRoutes";
const Project = (props) => {
	return (
		<>
			<ProjectRoutes />
		</>
	);
};

export default Project;
