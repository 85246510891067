import React from "react";
import { useForm } from "react-hook-form";

import { useHistory, useParams } from "react-router-dom";
import { Button, Box } from "@mui/joy";
import useAuthContainer from "../../../stores/Auth";
import "react-tagsinput/react-tagsinput.css";
import {
	Rooms,
	Espaces,
	Salles,
	ImageGallery2,
	TeamBuilding,
	Loisir,
	Rse,
	Restauration,
	FeaturedImage,
	BedRooms,
	General,
} from "../partials";
import { useTranslation } from "react-i18next";
import Helpers from "../../../utils/Helpers"; // Assurez-vous que le chemin d'importation est correct
import Place from "models/places";

function EditPlaceForm({ create, adminMode, close }) {
	const { t } = useTranslation();
	const {
		register,
		setValue,
		reset,
		control,
		watch,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm();

	const [saving, setSaving] = React.useState(false);
	const { user } = useAuthContainer.useContainer();
	const history = useHistory();
	const { placeID } = useParams();
	const [value, setVal] = React.useState(null);
	const [newImages, setNewImages] = React.useState([]);

	const PlaceEntity = new Place();

	const fetchPlace = async () => {
		try {
			const place = await PlaceEntity.getPlace(placeID);

			if (place) {
				reset({
					...place,
					cadre: Helpers.checkIsArray(place.cadre), // Assurez-vous que 'cadre' est toujours un tableau
					activites_loisir: Helpers.checkIsArray(
						place.activites_loisir,
					), // Assurez-vous que 'cadre' est toujours un tableau
					label_rse: Helpers.checkIsArray(place.label_rse), // Assurez-vous que 'cadre' est toujours un tableau
					equipement_technique: Helpers.checkIsArray(
						place.equipement_technique,
					), // Assurez-vous que 'cadre' est toujours un tableau
				});
				setVal(place);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	React.useEffect(() => {
		if (placeID && !create) {
			fetchPlace();
		}
	}, [setValue, reset]);

	const saveDatas = async (datas) => {
		const dat = {
			...datas,
			publish: true,
			created_at: new Date(),
		};

		if (create) {
			dat.uid = user?.uid;
		}

		Object.assign(PlaceEntity, dat);

		try {
			if (create) {
				// Spécifiez la collection dans laquelle vous souhaitez ajouter les données
				const added = await PlaceEntity.addPlace();
				console.log("Document written with");
				reset(); // Réinitialisez le formulaire après la soumission réussie
				history.push("/places");
			} else {
				const updatedDocs = await PlaceEntity.updatePlace(placeID);
				if (!adminMode) {
					history.push(`/places/view/${placeID}`);
					reset();
				} else {
					close(datas);
				}
			}
		} catch (error) {
			console.error("Error adding document: ", error);
		}
	};

	const onSubmit = async (data) => {
		setSaving(true);
		await saveDatas(Helpers.cleanObject(data));
		setNewImages([]);
		setSaving(false);
	};

	return (
		<Box
			sx={{
				pb: 10,
				".MuiInput-root , .MuiTextarea-root,.MuiSelect-root,.react-tagsinput":
					{
						maxWidth: "500px",
						width: "100%",
					},
			}}
		>
			<form
				onSubmit={handleSubmit(onSubmit)}
				style={{ pointerEvents: saving && "none" }}
			>
				{/*<Rse />*/}
				{/*GENERAL*/}
				<Box sx={{ background: "white" }}>
					<FeaturedImage
						values={watch("featured_image")}
						setValue={setValue}
					/>
					<ImageGallery2
						values={watch("images")}
						setValue={setValue}
					/>
				</Box>

				<General
					defaultAdress={value?.adresse || ""}
					errors={errors}
					register={register}
					tags={watch("tags") || []}
					control={control}
					setTags={(t) => setValue("tags", t, { shouldDirty: true })}
					setValue={setValue}
				/>

				{/*CHAMBRE*/}

				<Rooms register={register} errors={errors}>
					<BedRooms
						register={register}
						errors={errors}
						control={control}
						setValue={setValue}
						datas={!create ? value?.bedrooms || [] : []}
					/>
				</Rooms>

				{/*SALLES*/}

				<Salles
					register={register}
					errors={errors}
					control={control}
					setValue={setValue}
					datas={!create ? value?.rooms || [] : []}
				/>
				{/*SALLES*/}

				<Espaces
					register={register}
					errors={errors}
					control={control}
					setValue={setValue}
					datas={!create ? value?.espaces || [] : []}
				/>

				{/*Loisir*/}
				<Loisir
					errors={errors}
					register={register}
					control={control}
					setValue={setValue}
				/>
				{/*SALLES*/}

				<Restauration
					register={register}
					errors={errors}
					control={control}
					setValue={setValue}
					datas={!create ? value?.restauration || [] : []}
				/>
				<TeamBuilding
					register={register}
					errors={errors}
					control={control}
					setValue={setValue}
					datas={!create ? value?.team_building || [] : []}
				/>

				{/*ACCESSIBILITE*/}

				{/*<Accessibility
				register={register}
				control={control}
				watch={watch}
			/>*/}

				<Box
					sx={{
						p: 2,
						background: "white",
						position: "fixed",
						zIndex: "2",
						bottom: "10px",
						width: "79vw",
						boxShadow: "0px 0px 20px #00000025",
						borderRadius: "9px",
					}}
				>
					<Button
						fullWidth
						disabled={saving}
						loading={saving}
						type="submit"
						color={isDirty ? "primary" : "neutral"}
					>
						{saving
							? t("saving")
							: create
							? t("create")
							: t("save")}
					</Button>
				</Box>
			</form>
		</Box>
	);
}

export default EditPlaceForm;
