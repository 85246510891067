import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Modal, ModalOverflow, ModalDialog } from "@mui/joy";
import PropTypes from "prop-types";
import { ProjectChangeStatusForm } from "forms/projects";
import { useParams } from "react-router-dom";

const UpdateStatusModal = ({ open, close, setDatas, id, status }) => {
	const { t } = useTranslation();
	const { projectID } = useParams();

	const end = (datas) => {
		close();
		setDatas(datas);
	};
	return (
		<Modal open={open} onClose={close}>
			<ModalOverflow>
				<ModalDialog sx={{ minWidth: "400px" }}>
					<ProjectChangeStatusForm
						id={id}
						onEnd={end}
						status={status}
					/>
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default UpdateStatusModal;
UpdateStatusModal.propTypes = {};
UpdateStatusModal.defaultProps = {};
