import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { ArrowBack, Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { CreateModelFrom } from "../.";
import useAuthContainer from "stores/Auth";

const Header = ({
	placeID,
	createModel,
	datas,
	createModelModal,
	setCreateModelModal,
}) => {
	const { t } = useTranslation();
	const {
		user: { uid },
		userAccount,
	} = useAuthContainer.useContainer();

	return (
		<CreateModelFrom
			open={createModelModal}
			setOpen={setCreateModelModal}
			placeDatas={{ ...datas.data(), id: datas.id }}
			createModel={createModel}
		/>
	);
};

export default Header;
Header.propTypes = {
	placeID: PropTypes.string,
	datas: PropTypes.object,
};
Header.defaultProps = {};
