import React, { useState, useEffect } from "react";
import useAuthContainer from "stores/Auth";
import { withGlobalContainer } from "stores/Global";
import { SavingDocumentLoader } from "../../UI";
import { useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@mui/joy";
import {
  Header,
  Menu,
  Footer,
  EventMenu,
  PlaceMenu,
  ProjectMenu,
} from "./partials";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { RequestDocumentForm } from "forms/documents";

const BOLayout = ({
  children,
  title,
  showEventMenu,
  showPlaceMenu,
  showProjectMenu,
  isDocumentStatusMonitoring,
  documentStatus,
  documentID,
  backTo,
  noLateralMenu,
  admin,
}) => {
  const { user, userAccount } = useAuthContainer.useContainer();
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:850px)");
  const navigate = useHistory();

  const [menuWidth, setMenuWidth] = useState("250px");
  const [showMenu, setShowMenu] = useState(false);
  const [loginBackDrop, setLoginBackDrop] = useState(false);

  useEffect(() => {
    setLoginBackDrop(!user || !userAccount);

    if (user && userAccount && !userAccount?.additionnalDatasAdded) {
      navigate.push("/login/informations");
    }
  }, [user, userAccount, navigate]);

  const menuStyle = {
    width: matches ? "80vw" : `calc(${menuWidth} - 1px)`,
    transition: "ease 500ms",
    borderRight: "1px solid #eee",
    height: "100vh",
    position: "fixed",
    overflow: "auto",
    zIndex: 1003,
    display: noLateralMenu ? "none" : "block",
    transform: showMenu || !matches ? "translateX(0)" : "translateX(-150vw)",
  };

  if (loginBackDrop) {
    return (
      <Box
        sx={{
          display: "block",
          position: "fixed",
          zIndex: 4000000,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "white",
          p: 2,
        }}
      >
        <Typography>{t("you_need_to_be_connected")}</Typography>
        <Button onClick={() => navigate.push("/login")}>{t("connect")}</Button>
      </Box>
    );
  }

  return (
    <Box>
      {/* Header */}
      <Box
        sx={{
          position: "fixed",
          zIndex: showMenu ? 0 : 1,
          background: "white",
          right: 0,
          width: noLateralMenu
            ? "100%"
            : matches
            ? "100%"
            : `calc(100% - ${menuWidth})`,
        }}
      >
        <Header
          setShowMenu={setShowMenu}
          showMenu={showMenu}
          isMobile={matches}
          title={t(title)}
          userAccount={userAccount}
          isDocumentStatusMonitoring={isDocumentStatusMonitoring}
          documentStatus={documentStatus}
          backTo={backTo}
        />
      </Box>

      {/* Content */}
      <Box
        sx={(theme) => ({
          display: "flex",
          position: "fixed",
          width: "100%",
          overflow: "auto",
          background: theme.vars.palette.primary[10],
        })}
      >
        {/* Overlay for mobile menu */}
        {showMenu && matches && (
          <Box
            onClick={() => setShowMenu(false)}
            sx={{
              background: "rgba(0,0,0,0.6)",
              position: "fixed",
              height: "100vh",
              width: "100vw",
              top: 0,
              left: 0,
              zIndex: 1000,
            }}
          />
        )}

        {/* Side Menu */}
        <Box sx={{ background: "white" }}>
          {showEventMenu && <EventMenu id={documentID} style={menuStyle} />}
          {showPlaceMenu && documentID && <PlaceMenu id={documentID} style={menuStyle} />}
          {showProjectMenu && <ProjectMenu id={documentID} style={menuStyle} />}
          {!showEventMenu && !showPlaceMenu && !showProjectMenu && (
            <Menu
              isPartner={userAccount?.isApproved}
              isAdmin={userAccount?.role === "admin"}
              style={menuStyle}
              admin={admin}
            />
          )}
        </Box>

        {/* Main Content */}
        <Box
          sx={(theme) => ({
            margin: `0 auto auto ${noLateralMenu ? "0" : !matches ? menuWidth : ""} !important`,
            paddingTop: 12,
            paddingRight: !matches ? 2 : 0,
            paddingLeft: !matches ? 2 : 0,
            width: matches ? "97%" : "100%",
            transition: "ease 500ms",
            height: "100vh",
            position: "relative",
          })}
        >
          {children}
        </Box>
      </Box>

      {/* Document Form */}
      <RequestDocumentForm
        uid={user?.uid}
        open={
          !userAccount?.isApproved &&
          userAccount?.additionalDatas?.profil_type === "PartnerLocation"
        }
      />
    </Box>
  );
};

export default withGlobalContainer(BOLayout);
