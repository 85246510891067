import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Modal, ModalOverflow, ModalDialog } from "@mui/joy";
import PropTypes from "prop-types";
import { ProjectBriefForm } from "forms/projects";
import { useParams } from "react-router-dom";

const BriefModalEdit = ({ open, close, briefDatas, setDatas }) => {
	const { t } = useTranslation();
	const { projectID } = useParams();

	const end = (datas) => {
		close();
		setDatas(datas);
	};
	return (
		<Modal open={open} onClose={close}>
			<ModalOverflow>
				<ModalDialog sx={{ minWidth: "400px" }}>
					<ProjectBriefForm
						datas={{ ...briefDatas, id: projectID }}
						mode="edition"
						onEnd={end}
					/>
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default BriefModalEdit;
BriefModalEdit.propTypes = {};
BriefModalEdit.defaultProps = {};
