import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Divider, Chip, Button } from "@mui/joy";
import { Add } from "@mui/icons-material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const StatusColors = {
	waiting: "warning",
	active: "primary",
	canceled: "danger",
};

const ProjectHeaderSection = ({
	openPropositionModal,
	project_name,
	status,
	openStatus,
}) => {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				pb: 2,
				mb: 2,
			}}
		>
			<Box
				sx={{
					mb: 1,
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					borderBottom: "3px solid white",
					alignItems: "end",
					pb: 1,
				}}
			>
				<Box>
					<Button
						component={Link}
						color="neutral"
						to="/projects"
						style={{
							textDecoration: "none",
							cursor: "pointer",
						}}
					>
						{t("to_my_projects")}
					</Button>
					<Typography level="h2">{project_name}</Typography>
				</Box>
				<Button onClick={openPropositionModal} sx={{ background: "" }}>
					<Add /> {t("add_a_place")}
				</Button>
			</Box>
			<Chip onClick={openStatus} size="lg" color={StatusColors[status]}>
				{t(status)}
			</Chip>
		</Box>
	);
};

export default ProjectHeaderSection;
ProjectHeaderSection.propTypes = {
	project_name: PropTypes.string,
	status: PropTypes.string,
};
ProjectHeaderSection.defaultProps = {
	project_name: "Project Name",
	status: "waiting",
};
