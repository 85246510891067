import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, Stack, IconButton } from "@mui/joy";
import { Add, Edit, Delete, Upload } from "@mui/icons-material";
import PropTypes from "prop-types";
import { CreateTeamBuildingModal } from "./partials";
import { WhiteContainer } from "UI";
import SimpleTable from "UI/Tables/SimpleTable/SimpleTable";
import TeamBuilding from "models/team_buildings";

const TeamBuildingEntity = new TeamBuilding();
const TeamBuildingsList = (props) => {
	const { t } = useTranslation();
	const [selectedDatas, setSelectedDatas] = React.useState(null);
	const [openModal, setOpenModal] = React.useState(false);
	const [datas, setDatas] = React.useState(null);

	const fetchTeamBuildings = async () => {
		try {
			await TeamBuildingEntity.getTeamBuildings([], (d) => {
				setDatas(d);
			});
		} catch (e) {
			console.log("Failed to fetch team buildings", e.message);
		}
	};

	React.useEffect(() => {
		fetchTeamBuildings();
	}, []);

	const deleteTeamBuilding = (id) => {
		TeamBuildingEntity.deleteTeamBuilding(id);
	};

	const handleFileUpload = async (event) => {
		const file = event.target.files[0];
		if (!file) return;

		try {
			const fileContent = await file.text();
			let jsonData = JSON.parse(fileContent);

			// Nettoyage des hashtags : suppression des # et conversion en tableau
			jsonData = jsonData.map((item) => {
				if (item.team_building_hashtags) {
					item.team_building_hashtags = item.team_building_hashtags
						.split(/[, ]+/) // Séparation par virgule ou espace
						.map((tag) => tag.replace(/^#/, "").trim()) // Suppression du #
						.filter((tag) => tag.length > 0); // Filtrer les entrées vides
				} else {
					item.team_building_hashtags = [];
				}
				return item;
			});

			// Importation dans Firebase
			for (const item of jsonData) {
				const teamBuilding = new TeamBuilding(item);
				await teamBuilding.addTeamBuilding();
			}

			alert("Importation réussie !");
			fetchTeamBuildings(); // Rafraîchissement de la liste
		} catch (error) {
			console.error("Erreur d'importation :", error);
			alert("Erreur lors de l'importation du fichier JSON.");
		}
	};

	const TypographyStyle = {
		maxWidth: "100%",
		overflow: "hidden",
		textOverflow: "ellipsis",
	};

	const columns = [
		t("team_building_name"),
		t("team_building_type"),
		t("team_building_hashtags"),
		"",
	];

	const actions = [
		(d) => (
			<Stack direction="row" spacing={1}>
				<IconButton
					variant="outlined"
					color="primary"
					onClick={() => setSelectedDatas(d)}
				>
					<Edit />
				</IconButton>
				<IconButton
					variant="outlined"
					color="primary"
					onClick={() => deleteTeamBuilding(d.id)}
				>
					<Delete />
				</IconButton>
			</Stack>
		),
	];

	const fields = {
		team_building_name: (v) => (
			<Typography sx={TypographyStyle}>{v}</Typography>
		),
		team_building_type: (v) => (
			<Typography sx={TypographyStyle}>{t(v)}</Typography>
		),
		team_building_hashtags: (v) => (
			<Typography sx={TypographyStyle}>{v?.join(", ")}</Typography>
		),
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
			<Box>
				<Stack direction="row" spacing={2}>
					<Button color="primary" onClick={() => setOpenModal(true)}>
						<Stack direction="row" spacing={1}>
							<Add />
							<Typography sx={{ color: "white" }}>
								{t("add_team_building")}
							</Typography>
						</Stack>
					</Button>
					<Button component="label" color="secondary">
						<Stack direction="row" spacing={1}>
							<Upload />
							<Typography sx={{ color: "white" }}>
								{t("import_json")}
							</Typography>
						</Stack>
						<input
							type="file"
							accept="application/json"
							hidden
							onChange={handleFileUpload}
						/>
					</Button>
				</Stack>
			</Box>

			<WhiteContainer>
				<SimpleTable
					inputProps={{
						stickyHeader: true,
						stickyFooter: true,
						hoverRow: true,
						borderAxis: "both",
					}}
					style={{
						maxWidth: "100%",
						overflow: "auto",
						background: "white",
						borderRadius: "10px",
						mb: 2,
					}}
					head={columns}
					datas={datas}
					fields={fields}
					actions={actions}
				/>
			</WhiteContainer>
			<CreateTeamBuildingModal
				datas={selectedDatas}
				open={Boolean(selectedDatas) || openModal}
				close={() => {
					setSelectedDatas(null);
					setOpenModal(false);
				}}
			/>
		</Box>
	);
};

export default TeamBuildingsList;
TeamBuildingsList.propTypes = {};
TeamBuildingsList.defaultProps = {};
