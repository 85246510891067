import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import { Apartment } from "@mui/icons-material";
import PropTypes from "prop-types";
import { FilterContainer } from "Components/algolia";
import { RefinementList } from "react-instantsearch";

const Filters = React.memo(() => {
	const { t } = useTranslation();

	const filters = React.useMemo(
		() => [
			{
				sectionTitle: "team_building_type",
				Icon: Apartment,
				content: [
					{
						title: "team_building_type",
						refinement: (
							<RefinementList
								attribute="team_building_type"
								transformItems={(items) =>
									items.map((item) => ({
										...item,
										label: t(item.label),
									}))
								}
							/>
						),
					},
				],
			},
		],
		[t],
	);

	return (
		<FilterContainer
			terms={{ stats: "finded_team_buildings" }}
			filters={filters}
		/>
	);
});

export default Filters;
Filters.propTypes = {};
Filters.defaultProps = {};
