import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import {
	Apartment,
	FmdGood,
	LocalHotel,
	Nightlife,
	Euro,
	Visibility,
	Landscape,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import { FilterContainer, SliderAlgolia } from "Components/algolia";
import { RefinementList, RangeInput } from "react-instantsearch";
import useAuthContainer from "stores/Auth";

const PlaceFilters = React.memo(() => {
	const { t } = useTranslation();
	const { userAccount } = useAuthContainer.useContainer();
	const role = userAccount?.role || null;

	const transformTypeLieu = React.useCallback(
		(items) => {
			return items.map((item) => ({
				...item,
				label: t(item.label),
			}));
		},
		[t],
	);

	const filters = React.useMemo(() => {
		const baseFilters = [
			{
				sectionTitle: "place_type",
				Icon: Apartment,
				content: [
					{
						title: "places_type",
						refinement: (
							<RefinementList
								attribute="type_lieu"
								transformItems={transformTypeLieu}
							/>
						),
					},
				],
			},
			{
				sectionTitle: "location",
				Icon: FmdGood,
				content: [
					{
						title: "Localisation",
						refinement: (
							<RefinementList
								attribute="region"
								transformItems={transformTypeLieu}
							/>
						),
					},
					{
						title: "country",
						refinement: (
							<RefinementList
								attribute="pays"
								transformItems={transformTypeLieu}
							/>
						),
					},
				],
			},
			{
				sectionTitle: "hebergement",
				Icon: LocalHotel,
				content: [
					{
						title: "nombre_couchage_max",
						refinement: (
							<RangeInput
								attribute="nombre_couchage_max"
								translations={{
									separatorElementText: t("-"),
									submitButtonText: t("Ok"),
								}}
							/>
						),
					},
					{
						title: "nombre_de_chambres",
						refinement: (
							<RangeInput
								attribute="nombre_de_chambres"
								translations={{
									separatorElementText: t("-"),
									submitButtonText: t("Ok"),
								}}
							/>
						),
					},
					{
						title: "nombre_couchage_indiv",
						refinement: (
							<RangeInput
								attribute="nombre_couchage_indiv"
								translations={{
									separatorElementText: t("-"),
									submitButtonText: t("Ok"),
								}}
							/>
						),
					},
					{
						title: "capacite_en_twin",
						refinement: (
							<RangeInput
								attribute="capacite_en_twin"
								translations={{
									separatorElementText: t("-"),
									submitButtonText: t("Ok"),
								}}
							/>
						),
					},
				],
			},
			{
				sectionTitle: "Salles",
				Icon: Nightlife,
				content: [
					{
						title: "capacite_salle_max_type",
						refinement: (
							<RefinementList
								attribute="capacite_salle_max.type"
								transformItems={transformTypeLieu}
							/>
						),
					},
					{
						title: "capacite_salle_max_capacite",
						refinement: (
							<SliderAlgolia
								attribute="capacite_salle_max.capacite"
								label={t("nrb_places")}
								labelAdornment={""}
							/>
						),
					},
				],
			},
			{
				sectionTitle: "prices",
				Icon: Euro,
				content: [
					{
						title: "tarifs_seminaire_residentiel",
						refinement: (
							<SliderAlgolia
								attribute="tarifs_seminaire_residentiel"
								label={t("Prices")}
								labelAdornment={"€"}
							/>
						),
					},
					{
						title: "tarifs_journee_etude",
						refinement: (
							<SliderAlgolia
								attribute="tarifs_journee_etude"
								label={t("Prices")}
								labelAdornment={"€"}
							/>
						),
					},
				],
			},
			{
				sectionTitle: "mood",
				Icon: Landscape,
				content: [
					{
						title: "cadre",
						refinement: (
							<RefinementList
								attribute="cadre"
								transformItems={transformTypeLieu}
							/>
						),
					},
				],
			},
		];

		if (role === "admin") {
			baseFilters.push({
				sectionTitle: "visibility",
				Icon: Visibility,
				content: [
					{
						title: "visibility",
						refinement: (
							<RefinementList
								attribute="publish"
								transformItems={(items) =>
									items.map((item) => ({
										...item,
										label:
											item.label === "true"
												? t("visible")
												: t("hidden"),
									}))
								}
							/>
						),
					},
				],
			});
		}

		return baseFilters;
	}, [role, t, transformTypeLieu]);

	return <FilterContainer filters={filters} />;
});

export default PlaceFilters;

PlaceFilters.propTypes = {};
PlaceFilters.defaultProps = {};
