import React from "react";
import { useTranslation } from "react-i18next";
import {
	Modal,
	ModalDialog,
	ModalOverflow,
	Box,
	Typography,
	Card,
	Button,
} from "@mui/joy";
import PropTypes from "prop-types";
import Icons from "assets/icons/Icons";
import bedTypes from "datas/places/places_bed_type.json";

const PlaceRoomModalCard = ({
	image,
	nom,
	description,
	nbr_pers,
	size,
	qty_available,
	bed_type,
}) => {
	const { t } = useTranslation();
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<Box
				sx={{
					height: "370px",
					mr: "10px",
					width: "340px",
					cursor: "pointer",
				}}
				onClick={() => setOpen(true)}
			>
				<Box
					sx={{
						background: `url("${
							image?.full || image
						}") no-repeat center center / cover`,
						height: "210px",
						borderRadius: "5px",
						width: "340px",
					}}
				></Box>
				<Box sx={{ p: 2 }}>
					<Typography>
						<b>{nom}</b>
					</Typography>
					<Typography
						sx={{
							height: "40px",
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
						paragraph
						variant="body2"
					>
						{description}
					</Typography>
				</Box>
			</Box>
			<Modal open={open} onClose={() => setOpen(false)}>
				<ModalDialog sx={{ width: "900px" }}>
					<Typography level="h3">
						<b>{nom}</b>
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							columnGap: "17px",
							rowGap: "7px",
							flexWrap: "wrap",
						}}
					>
						{nbr_pers && (
							<Typography
								startDecorator={
									<Icons name="Pictos Weevapp_Couchage max" />
								}
							>
								{t("nbr_pers_room_modal", { data: nbr_pers })}
							</Typography>
						)}
						{size && (
							<Typography
								startDecorator={
									<Icons name="Pictos Weevapp_Surface max " />
								}
							>
								{t("size_room_modal", { data: size })}
							</Typography>
						)}
						{bed_type &&
							Object.entries(bed_type).map(([bed, value]) => {
								if (value?.selected) {
									const icon = (
										<Icons
											name={
												bedTypes.find(
													(b) => b.label === bed,
												)?.icons
											}
										/>
									);
									return (
										<Typography
											startDecorator={icon}
											key={bed}
										>
											{t(bed)} ({value.qty})
										</Typography>
									);
								}
								return null;
							})}
						{qty_available && (
							<Typography
								startDecorator={
									<Icons name="Pictos Weevapp_Hôtel" />
								}
							>
								{t("qty_available_room_modal", {
									data: qty_available,
								})}
							</Typography>
						)}
					</Box>
					<Box
						sx={{
							background: `url("${
								image?.full || image
							}") no-repeat center center / cover`,
							height: "350px",
							width: "100%",
							maxWidth: "100%",
							borderRadius: "5px",
						}}
					></Box>
					<Box sx={{ p: 2 }}>
						<Typography>{t("Description")}:</Typography>
						<Typography
							sx={{
								whiteSpace: "pre-wrap",
							}}
							variant="body1"
							paragraph
						>
							{description}
						</Typography>
					</Box>
				</ModalDialog>
			</Modal>
		</>
	);
};

export default PlaceRoomModalCard;
PlaceRoomModalCard.propTypes = {
	image: PropTypes.string,
	nom: PropTypes.string,
	description: PropTypes.string,
};
PlaceRoomModalCard.defaultProps = {
	image: "",
	nom: "",
	description: "",
};
