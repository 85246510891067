import FirestoreService from "services/firestoreServices";
import Helpers from "utils/Helpers";
import { where, orderBy } from "firebase/firestore";

/**
 * Creates an instance of the Animation class.
 * @constructor
 * @param {string} params.animation_name
 * @param {string} params.animation_type
 * @param {string} params.animation_rse
 * @param {string} params.animation_description
 * @param {Array} params.animation_hashtags
 * @param {Number} params.animation_min_pers
 * @param {Number} params.animation_max_pers
 * @param {Number} params.animation_price
 * @param {Number} params.animation_price_type
 * @param {Number} params.animation_featured_image
 * @param {Number} params.animation_prep_time
 * @param {Number} params.animation_duration
 * @param {string} params.created_at
 * @param {Object} params.modified_at - Date de modification
 */

class Animation {
    constructor(params) {
        Object.assign(this, params);
        this.modified_at = new Date();

        this.firestoreService = new FirestoreService("animations");
    }

    async getAnimations(clause, onUpdate) {
        try {
            const animation = await this.firestoreService.getDocuments(
                clause,
                true,
                onUpdate,
            );
            return animation;
        } catch (error) {
            console.error("Failed to fetch animation:", error);
            throw error;
        }
    }

    async getAnimationsPagination(lastVisible = null, constraints = []) {
        try {
            const result = await this.firestoreService.getDocuments(
                constraints,
                false,
                null,
                20,
                lastVisible,
            );
            if (lastVisible) {
                return {
                    documents: result.documents,
                    lastVisible: result.lastVisible,
                    no_more_data: result.no_more_data,
                };
            } else {
                return result;
            }
        } catch (error) {
            console.error("Failed to fetch events:", error);
            throw error;
        }
    }

    async getAnimation(id) {
        try {
            const animation = await this.firestoreService.getDocument(id);
            return animation;
        } catch (error) {
            console.error("Failed to fetch animation:", error);
            throw error;
        }
    }

    async addAnimation() {
        try {
            const animation = await this.firestoreService.addDocument(
                this.toJSON(),
            );
            return { id: animation.id, ...this.toJSON() };
        } catch (error) {
            console.error("Failed to add animation:", error);
            throw error;
        }
    }

    async deleteAnimation(id) {
        try {
            await this.firestoreService.deleteDocument(id);
        } catch (error) {
            console.error("Failed to delete animation:", error);
            throw error;
        }
    }

    async updateAnimation(id) {
        try {
            const updatedAnimation = await this.firestoreService.updateDocument(
                id,
                this.toJSON(),
                [],
            );
            return updatedAnimation;
        } catch (error) {
            console.error("Failed to update animation:", error);
            throw error;
        }
    }

    toJSON() {
        let data = {};
        const properties = {
            modified_at: this.modified_at,
            created_at: this.created_at,
            animation_name: this.animation_name,
            animation_type: this.animation_type,
            animation_rse: this.animation_rse,
            animation_description: this.animation_description,
            animation_hashtags: this.animation_hashtags,
            animation_min_pers: this.animation_min_pers,
            animation_max_pers: this.animation_max_pers,
            animation_featured_image: this.animation_featured_image,
            animation_price: this.animation_price,
            animation_price_type: this.animation_price_type,
            animation_prep_time: this.animation_prep_time,
            animation_duration: this.animation_duration,
        };

        // Add only properties that are not undefined
        for (let key in properties) {
            if (properties[key] !== undefined) {
                data[key] = properties[key];
            }
        }

        return data;
    }
}

export default Animation;
