import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, List, ListItem, IconButton, Stack } from "@mui/joy";
import { Delete, AttachFile, FileDownload } from "@mui/icons-material";
import PropTypes from "prop-types";
import Project from "models/projects";
import { storage } from "utils/MyFirebase";
import { ref, deleteObject } from "firebase/storage";
import { useParams } from "react-router-dom";
import { arrayRemove } from "firebase/firestore";

const ProjectInstance = new Project();

const SharedDocuments = ({ setDatas, datas }) => {
	const { t } = useTranslation();
	const { projectID } = useParams();

	const deleteDocument = async (documentToDelete) => {
		try {
			// Référence Firestore du projet
			ProjectInstance.project_documents = arrayRemove(documentToDelete);
			await ProjectInstance.updateProject(projectID);

			// Supprimer le fichier dans Cloud Storage
			if (documentToDelete.fileUrl) {
				const fileRef = ref(storage, documentToDelete.fileUrl);
				await deleteObject(fileRef);
				console.log(
					"Fichier supprimé de Cloud Storage :",
					documentToDelete.fileUrl,
				);
			}

			setDatas({
				project_documents: datas?.project_documents.filter(
					(d) => d.id !== documentToDelete.id,
				),
			});

			console.log("Document supprimé avec succès :", documentToDelete);
		} catch (error) {
			console.error("Erreur lors de la suppression du document :", error);
		}
	};

	return (
		<List sx={{ width: "400px", maxWidth: "100%" }}>
			{datas?.project_documents?.map((doc) => (
				<ListItem
					sx={{
						border: "1px solid #eee",
						m: 1,
						p: 1,
						background: "#F1F1F1",
						borderRadius: "8px",
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<Typography>
						<AttachFile color="primary" /> {doc?.name}
					</Typography>
					<Box>
						<IconButton
							color="primary"
							component="a"
							target="_blank"
							href={`${doc?.fileUrl}`}
						>
							<FileDownload />
						</IconButton>
						<IconButton
							color="primary"
							onClick={() => deleteDocument(doc)}
						>
							<Delete />
						</IconButton>
					</Box>
				</ListItem>
			))}
		</List>
	);
};

export default SharedDocuments;
SharedDocuments.propTypes = {};
SharedDocuments.defaultProps = {};
