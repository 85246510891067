import React from "react";
import { useTranslation } from "react-i18next";
import {
	Modal,
	ModalDialog,
	Typography,
	Button,
	Box,
	Divider,
	Chip,
} from "@mui/joy";
import PropTypes from "prop-types";
import { AutocompleteSimple } from "UI";
import Project from "models/projects";
import { useForm, Controller } from "react-hook-form";
import useAuthContainer from "stores/Auth";

const MyModal = ({
	open,
	handleClose,
	selected,
	animation_name,
	animation_description,
	animation_hashtags,
	animation_price,
	objectID,
	animation_featured_image,
}) => {
	const { t } = useTranslation();
	const { user } = useAuthContainer.useContainer();
	const { control, handleSubmit, setValue, getValues, watch, reset } =
		useForm();
	const [project, setProject] = React.useState(null);
	const projectInstance = new Project();
	const [optionIsInProject, setOptionIsInProject] = React.useState(false);
	const [saved, setSaved] = React.useState(false);
	const [saving, setSaving] = React.useState(false);

	const projectId = watch("project");

	React.useEffect(() => {
		reset();
		setProject(null);
		setSaved(false);
		setOptionIsInProject(null);
	}, [objectID, open]);

	const searchProjectByName = async (searchTerm) => {
		if (!searchTerm) return [];
		const result = await projectInstance.searchProjectByName(
			searchTerm,
			user.uid,
		);
		return result.map((project) => ({
			label: `${project.project_name}`,
			value: project.id,
		}));
	};

	const fetchProject = async (id) => {
		const project = await projectInstance.getProject(id);
		setProject(project);
		setOptionIsInProject((prev) =>
			project?.project_animations?.find(
				(p) => (p?.id || p?.objectID) === objectID,
			),
		);
	};

	const handleAddToProject = async (d) => {
		setSaving(true);
		try {
			if (d?.project) {
				projectInstance.project_animations = [
					...(project?.project_animations || []),
					selected,
				];
				await projectInstance.updateProject(d.project);
				setSaved(true);
			}
		} catch (e) {
			console.log(e.error);
		}
		setSaving(false);
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<ModalDialog
				aria-labelledby="MyModal-title"
				aria-describedby="MyModal-description"
				sx={{ maxWidth: 500 }}
			>
				<Box
					sx={{
						height: 200,
						width: "auto",
						background: `url("${animation_featured_image}") center center / cover`,
					}}
				></Box>
				<Typography id="MyModal-title" level="h4" component="h2">
					{animation_name}
				</Typography>
				<Typography id="MyModal-description" sx={{ mt: 1 }}>
					{animation_description}
				</Typography>
				<Box
					sx={{
						mt: 1,
						mb: 1,
						display: "flex",
						flexDirection: "row",
						gap: 1,
						flexWrap: "wrap",
					}}
				>
					{animation_hashtags?.length > 0
						? animation_hashtags.map((hashtag) => (
								<Chip key={hashtag}>#{t(hashtag)}</Chip>
						  ))
						: animation_hashtags}
				</Box>
				<Typography
					sx={{
						height: "40px",
						fontSize: "14px",
						overflow: "hidden",
					}}
					paragraph
					color={"secondary"}
				>
					{animation_price?.toUpperCase()}
				</Typography>
				<Divider sx={{ m: 1 }} />

				<Box>
					<Typography>{t("add_to_project")}</Typography>
					<form onSubmit={handleSubmit(handleAddToProject)}>
						<Controller
							name="project"
							control={control}
							render={({ field }) => (
								<AutocompleteSimple
									placeholder={t("select_a_project")}
									label="Select Project"
									fetchOptions={searchProjectByName}
									onSelect={(value) => {
										if (value) {
											// Vérifie que value n'est pas null
											fetchProject(value.value);
											setValue("project", value.value);
											setValue("name", value.label);
										} else {
											// Réinitialisez les valeurs si aucun projet n'est sélectionné
											setValue("project", "	");
											setValue("name", "");
										}
									}}
								/>
							)}
						/>{" "}
						{projectId && !optionIsInProject && !saved && (
							<Button
								disabled={saving}
								loading={saving}
								type="submit"
								color="primary"
								sx={{ mt: 2 }}
							>
								{t("add_to_selected_project")}
							</Button>
						)}
						{optionIsInProject && (
							<Typography color="warning">
								{t("option_already_in_project")}
							</Typography>
						)}
						{saved && (
							<Typography color="success">
								{t("option_saved")}
							</Typography>
						)}
					</form>
				</Box>

				<Button
					type="button"
					onClick={handleClose}
					color="neutral"
					sx={{ mt: 2 }}
				>
					{t("close")}
				</Button>
			</ModalDialog>
		</Modal>
	);
};

MyModal.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
};

MyModal.defaultProps = {
	open: false,
	handleClose: () => {},
};

export default MyModal;
