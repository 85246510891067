import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, Stack } from "@mui/joy";
import { Add } from "@mui/icons-material";
import PropTypes from "prop-types";
import ProgramItem from "./ProgramItem";
import Event from "models/events";
import { where } from "firebase/firestore";
import { useHistory } from "react-router-dom";
import useAuthContainer from "stores/Auth";

const EntityEvent = new Event();

const EventPrograms = ({ project_id, setDatas, datas }) => {
	const { t } = useTranslation();
	const { user } = useAuthContainer.useContainer();
	const history = useHistory();
	const [creating, setCreating] = React.useState(false);
	const [events, setEvents] = React.useState(null);

	const createEventProgram = async () => {
		setCreating(true);
		try {
			EntityEvent.event_name = "Nouveau programme";
			EntityEvent.created_at = new Date();
			EntityEvent.event_description = "Description";
			EntityEvent.project_id = project_id;
			EntityEvent.uid = user.uid;

			const addedEvent = await EntityEvent.addEvent();

			history.push(`/event/${addedEvent.id}/general`);
		} catch (e) {
			console.log(e.message);
		}
		setCreating(false);
	};

	const fetchEvent = async () => {
		try {
			const events = await EntityEvent.getEventSync([
				where("uid", "==", user.uid),
				where("project_id", "==", project_id),
			]);

			if (events) {
				setEvents(events);
			}
		} catch (e) {
			console.log("cant fetch event", e.message);
		}
	};
	const deleteEvent = async (id) => {
		if (window.confirm(t("sure_delete_event_program"))) {
			try {
				const deleted = await EntityEvent.deleteEvent(id);

				if (events) {
					setEvents(events.filter((d) => d.id !== id));
				}
			} catch (e) {
				console.log("cant delete event", e.message);
			}
		}
	};

	const changeStatus = async (e, id) => {
		const val = e.currentTarget.checked;
		try {
			EntityEvent.status = val ? "active" : "inactive";
			// Mettez à jour l'état de l'événement dans Firebase
			await EntityEvent.updateEvent(id);

			// Mettez à jour localement les événements dans le state pour refléter le changement
			setEvents((prevEvents) =>
				prevEvents.map((event) =>
					event.id === id
						? { ...event, status: val ? "active" : "inactive" }
						: event,
				),
			);
		} catch (error) {
			console.error(
				"Erreur lors de la mise à jour du statut de l'événement :",
				error.message,
			);
		}
	};

	React.useEffect(() => {
		if (!events && user?.uid) {
			fetchEvent();
		}
	}, [project_id, user]);

	return (
		<Box sx={{}}>
			<Button
				disabled={creating}
				loading={creating}
				onClick={createEventProgram}
				sx={{ mb: 2 }}
			>
				<Stack direction="row">
					<Add />
					<Typography sx={{ color: "#FFF" }}>
						{t("add_a_event_program")}
					</Typography>
				</Stack>
			</Button>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					gap: 1,
					background: "#FAFAFA",
					p: 1,
					flexWrap: "wrap",
				}}
			>
				{events &&
					events.map((program) => (
						<ProgramItem
							changeStatus={(e) => changeStatus(e, program.id)}
							deleteEvent={() => deleteEvent(program.id)}
							key={program.id}
							{...program}
						/>
					))}
			</Box>
		</Box>
	);
};

export default EventPrograms;
EventPrograms.propTypes = {};
EventPrograms.defaultProps = {};
