import React from "react";
import { Switch, Route } from "react-router-dom";
import { Animations, TeamBuildings, Places } from "./views";

const PartnersRoutes = () => {
	return (
		<Switch>
			<Route exact path="/partners">
				<Places />
			</Route>
			<Route path="/partners/places">
				<Places />
			</Route>
			<Route path="/partners/animation">
				<Animations />
			</Route>
			<Route path="/partners/team-building">
				<TeamBuildings />
			</Route>
		</Switch>
	);
};

export default PartnersRoutes;
