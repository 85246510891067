import React from "react";
import { FormSeparator } from "UI";
import { Input, Typography, FormControl, FormHelperText } from "@mui/joy";
import { Group } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const ProjectPax = ({ register, error }) => {
	const { t } = useTranslation();
	return (
		<FormControl>
			<Input
				startDecorator={<Group color="primary" />}
				type="number"
				placeholder={t("Pax")}
				{...register("project_event_participants_qty", {
					required: t("project_event_participants_qty_required"),
				})}
			/>
			{error && <FormHelperText>{error.message}</FormHelperText>}
		</FormControl>
	);
};

export default ProjectPax;
