import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, IconButton, Divider } from "@mui/joy";
import { Edit } from "@mui/icons-material";
import PropTypes from "prop-types";
import Helpers from "utils/Helpers";

const ProjectBrief = ({
  project_event_date_period,
  project_event_day_duration,
  project_name,
  project_event_participants_qty,
  event_budget,
  event_budget_type,
  project_event_type,
  project_details,
  project_event_budget,
  project_event_budget_type,
  project_event_objective,
  project_event_date_stopped,
  project_event_start_date,
  project_event_end_date,
  project_event_seminar_type,
  handleOpenBriefModal,
}) => {
  const { t } = useTranslation();

  const formatDate = (d) => Helpers.fbDate(d, "DD-MM-YYYY");
  return (
    <Box sx={{ p: 1 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "19px",
          }}
        >
          {t("project_brief")}
        </Typography>
        <IconButton
          onClick={handleOpenBriefModal}
          color="primary"
          variant="outlined"
        >
          <Edit />
        </IconButton>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box>
        <ul style={{ listStyle: "none", padding: 0 }}>
          <li>
            <Typography>
              <b>{t("project_name")} :</b> {project_name}
            </Typography>
          </li>
          {project_event_date_stopped ? (
            <>
              <li>
                <Typography>
                  <b>{t("start_at")} :</b>{" "}
                  {formatDate(project_event_start_date)}
                </Typography>
              </li>
              <li>
                <Typography>
                  <b>{t("end_at")} :</b> {formatDate(project_event_end_date)}
                </Typography>
              </li>
            </>
          ) : (
            <>
              <li>
                <Typography>
                  <b>{t("project_event_day_duration")} :</b>{" "}
                  {project_event_day_duration}
                </Typography>
              </li>
              <li>
                <Typography>
                  <b>{t("project_event_date_period")} :</b>{" "}
                  {t(project_event_date_period?.split("_")[0])}
                  {project_event_date_period?.split("_")[1]}
                </Typography>
              </li>
            </>
          )}
        </ul>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box>
        <ul style={{ listStyle: "none", padding: 0 }}>
          <li>
            <Typography>
              <b>{t("project_event_participants_qty")}</b> :{" "}
              {project_event_participants_qty}
            </Typography>
          </li>
          <li>
            <Typography>
              <b>{t("budget_per_person")}</b> :{" "}
              {parseFloat(
                project_event_budget_type === "total"
                  ? project_event_budget / project_event_participants_qty
                  : project_event_budget
              ).toFixed(2)}{" "}
              €
            </Typography>
          </li>
        </ul>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box>
        <ul style={{ listStyle: "none", padding: 0 }}>
          <li>
            <Typography>
              <b>{t("project_event_type")}</b> : {t(project_event_type)}
            </Typography>
          </li>
        </ul>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Typography paragraph sx={{ fontSize: "13px", whiteSpace: "pre-wrap" }}>
        {project_details}
      </Typography>
    </Box>
  );
};

export default ProjectBrief;
ProjectBrief.propTypes = {};
ProjectBrief.defaultProps = {};
