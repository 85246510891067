import React from "react";

import { WhiteBackdrop } from "../../../../components/UI";
import { BOLayout } from "../../../../components/layouts";
import NewProjectLayout from "./NewProjectLayout";
import { ProjectBriefForm } from "forms/projects";

const NewEvent = (props) => {
	return (
		<BOLayout title="create_new_project">
			<WhiteBackdrop text="new_project_creating" />
			<NewProjectLayout title="create_new_project">
				<ProjectBriefForm />
			</NewProjectLayout>
		</BOLayout>
	);
};

export default NewEvent;
