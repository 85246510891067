import React from "react";
import { Typography, Box } from "@mui/joy";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const WhiteContainerButton = ({ title, style, action, ...props }) => {
	const matches = useMediaQuery("(max-width:600px)");
	const { t } = useTranslation();
	return (
		<Box
			sx={{
				borderRadius: "5px",
				position: "relative",
				background: "#FFF",
				p: !matches ? 3 : 2,
				mb: !matches ? 3 : 2,
				boxShadow: " 4px 4px 15px 0px rgba(0, 0, 0, 0.05)",
				...style,
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					mb: 2,
					pb: 2,
					borderBottom: "1px solid #eee",
				}}
			>
				<Box>
					{title && <Typography level="h5">{t(title)}</Typography>}
				</Box>
				<Box>{action && action}</Box>
			</Box>
			{props.children}
		</Box>
	);
};

export default WhiteContainerButton;
