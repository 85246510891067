import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { OptionItemCardCatalog } from "UI";

const TeamBuildingItem = ({
	team_building_name,
	team_building_description,
	team_building_price,
	team_building_featured_image,
	team_building_hashtags,
	team_building_rse,
	id,
	onSelect,
	selected,
}) => {
	const { t } = useTranslation();

	return (
		<OptionItemCardCatalog
			id={id}
			hastags={team_building_hashtags}
			title={team_building_name}
			description={`${team_building_description}  \n ${team_building_rse}`}
			extraInfo={team_building_price}
			image={team_building_featured_image}
			selected={selected}
			onSelect={onSelect}
		/>
	);
};

export default TeamBuildingItem;
TeamBuildingItem.propTypes = {};
TeamBuildingItem.defaultProps = {};
