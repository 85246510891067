import React from "react";
import items from "datas/events/event_type_list.json";
import { ChipCheckbox, FormSeparator } from "UI";
import { Input, FormControl, FormHelperText } from "@mui/joy";

const ProjectEventObjective = ({
	setValue,
	register,
	action,
	selectItem,
	error,
	getValues,
	watch,
}) => {
	let eventType = watch && watch("project_event_type");
	const objective = items.find((event) => event.label === eventType);

	return eventType ? (
		<>
			<FormSeparator required title="project_event_objective">
				<FormControl>
					<ChipCheckbox
						hasToolTips
						items={objective?.content || []}
						register={register}
						action={action}
						selectItem={selectItem}
						setValue={setValue}
						fieldName="project_event_objective"
					/>
					{error && <FormHelperText>{error.message}</FormHelperText>}
				</FormControl>
			</FormSeparator>
		</>
	) : null;
};

export default ProjectEventObjective;
