// UploadsListener.js
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { db, storage } from "utils/MyFirebase";
import {
	collection,
	addDoc,
	doc,
	onSnapshot,
	updateDoc,
	serverTimestamp,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { where } from "firebase/firestore";
import Place from "models/places";
import { Button } from "@mui/joy";

const PlaceInstance = new Place();

const firestore = db;

const MassPlaceImport = () => {
	const [file, setFile] = useState(null);
	const hardCodedUID = "zY1E2yfq3ZhcbkuswbS8KXlIadW2"; // UID fixe
	const [loading, setLoading] = useState(false);
	const [importedCount, setImportedCount] = useState(0);
	const [lastImportedId, setLastImportedId] = useState(null);
	const [parsing, setParsing] = React.useState(false);
	const [parsed, setParsed] = React.useState(0);
	const [parsingErrors, setParsingErrors] = React.useState("");
	const parserINTS = async () => {
		setParsing(true);

		try {
			// Récupérer les lieux
			const places = await PlaceInstance.getPlaces(
				[where("uid", "==", hardCodedUID)],
				false,
				false,
			);

			const TmpInstance = new Place();

			for (const place of places) {
				try {
					// Traitement des salles et configurations
					TmpInstance.rooms =
						place.rooms.map((r) => ({
							...r,
							configurations: r.configurations.map((config) => ({
								...config,
								capacite: parseInt(config.capacite, 10) || 0,
							})),
							superficie_salles: parseInt(r.superficie_salles),
							options: { lumiereDuJour: false, AccesPmr: false },
						})) || [];

					// Conversion des autres champs
					TmpInstance.nbr_salle_plus_50 =
						parseInt(place.nbr_salle_plus_50, 10) || 0;
					TmpInstance.nombre_de_chambres =
						parseInt(place.nombre_de_chambres, 10) || 0;
					TmpInstance.nombre_couchage_indiv =
						parseInt(place.nombre_couchage_indiv, 10) || 0;

					TmpInstance.tarifs_journee_etude =
						parseFloat(place.tarifs_journee_etude) || 0;
					TmpInstance.tarifs_seminaire_residentiel =
						parseFloat(place.tarifs_seminaire_residentiel) || 0;
					console.log(TmpInstance);

					// Mise à jour dans Firestore
					await TmpInstance.updatePlace(place.id);
					setParsed((prev) => prev + 1);
				} catch (error) {
					const newError = `${parsingErrors}\n ${place.nom} - ${place.id} ERROR`;
					setParsingErrors(newError);
					console.error(
						`Erreur lors du traitement de ${place.nom}:`,
						error,
					);
				}
			}
		} catch (e) {
			console.error("Erreur lors de la récupération des lieux :", e);
		}

		setParsing(false);
	};

	const handleFileChange = (e) => {
		setFile(e.target.files[0]);
	};

	const handleUpload = async () => {
		setLoading(true);
		if (!file) {
			console.error("Aucun fichier sélectionné.");
			setLoading(false);
			return;
		}

		const reader = new FileReader();
		reader.onload = async (e) => {
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, { type: "array" });
			const sheetName = workbook.SheetNames[0];
			const sheet = workbook.Sheets[sheetName];
			const jsonData = XLSX.utils.sheet_to_json(sheet);

			console.log("Données chargées depuis le fichier :", jsonData);

			for (const row of jsonData) {
				console.log("Traitement de la ligne :", row);
				const uid = hardCodedUID;
				const imageUrl = row.featured_image;
				const fileName = imageUrl?.split("/").pop();

				if (!imageUrl || !imageUrl.startsWith("http") || !fileName) {
					console.error(
						"URL invalide ou image manquante pour la ligne :",
						row,
					);
					setLoading(false);
					return;
				}

				try {
					const proxyUrl = "https://cors-anywhere.herokuapp.com/";
					console.log(
						`Tentative de téléchargement de l'image via proxy : ${
							proxyUrl + imageUrl
						}`,
					);
					const response = await fetch(proxyUrl + imageUrl);

					if (!response.ok) {
						throw new Error(
							`Le serveur a renvoyé une erreur HTTP ${response.status} : ${response.statusText}`,
						);
					}

					console.log(
						`Statut de la réponse: ${response.status} - ${response.statusText}`,
					);
					const blob = await response.blob();

					console.log(
						`Upload de l'image dans Cloud Storage : ${fileName}`,
					);
					const imageRef = ref(storage, `uploads/${uid}/${fileName}`);
					await uploadBytes(imageRef, blob);

					const publicUrl = await getDownloadURL(imageRef);
					console.log(
						`Image téléchargée avec succès. URL publique : ${publicUrl}`,
					);

					const description = `${row.description || ""} ${
						row.description_next || ""
					}`.trim();

					console.log(
						"Création du document dans la collection 'places'...",
					);

					let gpsCoordinates = { lat: null, lng: null };
					try {
						const formattedCoords = row.coordonnees_gps
							?.replace(/([a-zA-Z0-9_]+)\s*:/g, '"$1":')
							.replace(/'/g, '"');
						if (formattedCoords) {
							gpsCoordinates = JSON.parse(formattedCoords);
						}
					} catch (error) {
						console.error(
							"Erreur lors de l'analyse des coordonnées GPS :",
							row.coordonnees_gps,
							error,
						);
					}
					let region = null;
					if (gpsCoordinates.lat && gpsCoordinates.lng) {
						const reverseGeocodeUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${gpsCoordinates.lat}&lon=${gpsCoordinates.lng}`;
						try {
							const response = await fetch(reverseGeocodeUrl);
							const data = await response.json();
							if (data.address && data.address.state) {
								region = data.address.state;
								console.log(
									"Région récupérée depuis les coordonnées :",
									region,
								);
							} else {
								console.warn(
									"Aucune région trouvée pour les coordonnées :",
									gpsCoordinates,
								);
							}
						} catch (error) {
							console.error(
								"Erreur lors de la récupération de la région :",
								error,
							);
						}
					}
					let city = null;

					if (!gpsCoordinates.lat || !gpsCoordinates.lng) {
						// Récupération des coordonnées depuis l'adresse
						const geocodeUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
							row.adresse || "",
						)}`;
						try {
							const response = await fetch(geocodeUrl);
							const data = await response.json();
							if (data.length > 0) {
								gpsCoordinates = {
									lat: parseFloat(data[0].lat),
									lng: parseFloat(data[0].lon),
								};
								console.log(
									"Coordonnées récupérées depuis l'adresse :",
									gpsCoordinates,
								);
							} else {
								gpsCoordinates = { lat: 0, lng: 0 };
							}
						} catch (error) {
							console.error(
								"Erreur lors de la récupération des coordonnées depuis l'adresse :",
								error,
							);
							gpsCoordinates = { lat: 0, lng: 0 };
						}
					}

					PlaceInstance.nom = row.nom || "Nom non fourni";
					PlaceInstance.adresse =
						row.adresse || "Adresse non fournie";
					PlaceInstance.code_postal =
						row.code_postal || "Code postal non fourni";
					PlaceInstance.ville = row.ville || "Ville non fournie";
					PlaceInstance.coordonnees_gps = gpsCoordinates;
					PlaceInstance.region = region || "Région inconnue";
					PlaceInstance.created_at = new Date();
					PlaceInstance.featured_image = publicUrl;
					PlaceInstance.description = description;
					PlaceInstance.pays = "France";
					PlaceInstance.publish = true;
					PlaceInstance.rooms =
						JSON.parse(row.rooms.replace(/\\/g, "")).map((r) => ({
							...r,
							configurations: r.configurations.map((config) => ({
								...config,
								capacite: parseInt(config.capacite, 10) || 0, // Assure la conversion en entier
							})),
							superficie_salles: parseInt(r.superficie_salles),

							options: { lumiereDuJour: false, AccesPmr: false },
						})) || [];
					PlaceInstance.nbr_salle_plus_50 =
						parseInt(row.nbr_salle_plus_50) || 0;
					PlaceInstance.nombre_de_chambres =
						parseInt(row.nombre_de_chambres) || 0;
					PlaceInstance.nombre_couchage_indiv =
						parseInt(row.nombre_couchage_indiv) || 0;
					PlaceInstance.type_lieu =
						row.type_lieu || "Type non fourni";
					PlaceInstance.tarifs_journee_etude =
						parseFloat(row.tarifs_journee_etude) || null;
					PlaceInstance.tarifs_seminaire_residentiel =
						parseFloat(row.tarifs_seminaire_residentiel) || null;
					PlaceInstance.uid = uid;

					const placeRef = await PlaceInstance.addPlace();
					console.log(
						`Document créé dans 'places' avec ID : ${placeRef.id}`,
					);
					setImportedCount((prevCount) => prevCount + 1);
					setLastImportedId(`${placeRef.id} = ${row.nom}`);

					console.log(
						"Création du document dans la collection 'uploads'...",
					);
					await addDoc(collection(firestore, "uploads"), {
						contentType: blob.type,
						createdAt: serverTimestamp(),
						folder: "other",
						name: fileName,
						place: placeRef.id,
						uid: uid,
						url: publicUrl,
					});

					console.log(
						`Document créé dans 'uploads' pour : ${fileName}`,
					);
				} catch (error) {
					console.error(
						`Erreur lors du traitement de l'UID : ${uid}`,
						error,
					);
					console.error(
						`Dernier ID importé avant l'erreur : ${lastImportedId}`,
					);
					setLoading(false);
					return;
				}
			}

			alert(
				`Importation terminée avec ${importedCount} lieux importés !`,
			);
			setLoading(false);
		};

		reader.readAsArrayBuffer(file);
	};

	return (
		<div>
			<input type="file" accept=".xlsx" onChange={handleFileChange} />
			<Button
				type="button"
				onClick={handleUpload}
				disabled={!file}
				loading={loading}
			>
				Importer le fichier
			</Button>
			<div>{`Lieux importés : ${importedCount}`}</div>
			{lastImportedId && (
				<div>{`Dernier ID importé : ${lastImportedId}`}</div>
			)}
			<Button
				type="button"
				loading={parsing}
				disabled={parsing}
				onClick={parserINTS}
			>
				PARSE INTS
			</Button>
			<p>Lieux parsé : {parsed}</p>
			<textarea>{parsingErrors}</textarea>
		</div>
	);
};

export default MassPlaceImport;
