import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { AnimationsList } from "./views";

const Events = (props) => {
	const { t } = useTranslation();

	return (
		<Switch>
			<Route exact path="/admin/animations">
				<AnimationsList />
			</Route>
		</Switch>
	);
};

export default Events;
Events.propTypes = {};
Events.defaultProps = {};
