import React from "react";
import { Box, Typography, Modal, ModalDialog, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const OptionModal = ({
	open,
	onClose,
	title,
	description,
	price,
	image,
	actions,
	sx,
}) => {
	// Gestion de l'image qui peut être un objet ou une URL directe
	const resolvedImage =
		image?.full || image || "https://via.placeholder.com/150";
	const { t } = useTranslation();

	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog
				aria-labelledby="option-modal-title"
				aria-describedby="option-modal-description"
				sx={{
					maxWidth: "600px",
					borderRadius: "md",
					p: 3,
					...sx,
				}}
			>
				{/* Affichage de l'image */}
				{resolvedImage && (
					<Box
						sx={{
							borderRadius: "md",
							mb: 2,
							height: "200px",
							background: `url("${resolvedImage}") no-repeat center center / cover`,
						}}
					/>
				)}

				{/* Affichage du titre */}
				<Box sx={{ mb: 2 }}>
					<Typography id="option-modal-title" level="h4">
						{title}
					</Typography>
				</Box>

				{/* Affichage de la description */}
				<Box id="option-modal-description" sx={{ mb: 2 }}>
					{description && (
						<Typography level="body1" sx={{ mb: 1 }}>
							{description}
						</Typography>
					)}
				</Box>

				{/* Affichage du prix */}
				{price && (
					<Typography
						level="body2"
						color="secondary"
						sx={{ fontWeight: "bold", mb: 2 }}
					>
						{price}
					</Typography>
				)}

				{/* Actions */}
				<Box
					sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}
				>
					{actions || (
						<Button variant="outlined" onClick={onClose}>
							{t("close")}
						</Button>
					)}
				</Box>
			</ModalDialog>
		</Modal>
	);
};

OptionModal.propTypes = {
	open: PropTypes.bool.isRequired, // Contrôle si la modal est ouverte
	onClose: PropTypes.func.isRequired, // Fonction pour fermer la modal
	title: PropTypes.string.isRequired, // Titre affiché en haut
	description: PropTypes.string, // Texte de description
	price: PropTypes.string, // Prix ou autre information supplémentaire
	image: PropTypes.oneOfType([
		PropTypes.string, // URL directe
		PropTypes.shape({
			thumb: PropTypes.string, // URL de la version miniature
			full: PropTypes.string, // URL de la version complète
		}),
	]), // Image (URL ou objet)
	actions: PropTypes.node, // Actions supplémentaires (boutons, liens, etc.)
	sx: PropTypes.object, // Styles personnalisés pour le conteneur principal
};

OptionModal.defaultProps = {
	description: "",
	price: "",
	image: null,
	actions: null,
	sx: {},
};

export default OptionModal;
