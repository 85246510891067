import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, IconButton, Switch, Stack } from "@mui/joy";
import {
	Delete,
	Settings,
	Visibility,
	VisibilityOff,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import Helpers from "utils/Helpers";
import { Link } from "react-router-dom";

const formatDate = (d) => Helpers.fbDate(d, "DD-MM-YYYY");

const ProgramItem = ({
	event_name,
	status,
	id,
	event_description,
	created_at,
	create,
	deleteEvent,
	changeStatus,
}) => {
	const { t } = useTranslation();
	const [deleting, setDeleting] = React.useState(false);
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				rowGap: "10px",
				width: "250px",
				p: 1,
				boxShadow: "1px 0px 3px #00000020",
				borderRadius: "5px",
				background: "white",
			}}
		>
			<Box>
				<Typography sx={{ fontSize: "12px" }}>
					<b>{t("created_at")}</b> : {formatDate(created_at)}
				</Typography>
				<Typography>{event_name}</Typography>
			</Box>
			<Box
				sx={{
					justifyContent: "space-between",
					display: "flex",
					p: 1,
					flexDirection: "row",
					columnGap: 1,
					borderTop: "1px solid #888",
				}}
			>
				<Stack direction="row" spacing={1} alignItems="center">
					{status === "active" && <Visibility color="primary" />}
					{status !== "active" && <VisibilityOff color="neutral" />}
					<Switch
						checked={status === "active"}
						variant="solid"
						onChange={(e) => changeStatus(e)}
					/>
				</Stack>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						columnGap: "5px",
					}}
				>
					<IconButton
						onClick={async () => {
							setDeleting(true);
							await deleteEvent();
							setDeleting(false);
						}}
						variant="outlined"
						loading={deleting}
						disabled={deleting}
					>
						<Delete />
					</IconButton>
					<IconButton
						component={Link}
						to={`/event/${id}/general`}
						variant="outlined"
					>
						<Settings />
					</IconButton>
				</Box>
			</Box>
		</Box>
	);
};

export default ProgramItem;
ProgramItem.propTypes = {};
ProgramItem.defaultProps = {};
