import React from "react";
import {
	Box,
	IconButton,
	LinearProgress,
	Button,
	Stack,
	Typography,
	List,
	ListItem,
} from "@mui/joy";
import {
	Add,
	InsertDriveFile,
	Delete,
	GridView,
	Image,
	List as ListIcon,
} from "@mui/icons-material";

const MyFiles = ({
	fileInput,
	files,
	selectedFile,
	setSelectedFile,
	uploading,
	handleDelete,
	handleLoadMore,
}) => {
	const [list, setList] = React.useState(false);
	return (
		<>
			<Box sx={{ mb: 2, display: "flex", justifyContent: "right" }}>
				<IconButton onClick={() => setList(!list)}>
					{list ? <GridView /> : <ListIcon />}
				</IconButton>
			</Box>
			<Box
				component={list && List}
				sx={{
					flexWrap: "wrap",
					display: !list && "flex",
					flexDirection: !list && "row",
					gap: "8px",
				}}
			>
				{!list && (
					<Box
						onClick={() => fileInput.current.click()}
						sx={(theme) => ({
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							background: theme.vars.palette.primary[500],
							color: "white",
							p: 2,
							cursor: "pointer",
							border: "1px solid #888",
							borderRadius: "10px",
							width: "140px",
							height: "140px",
							overflow: "hidden",
							position: "relative",
							"&:hover": {
								opacity: 0.8,
							},
						})}
					>
						<Add sx={{ color: "white", fontSize: "50px" }} />
					</Box>
				)}
				{files.map((file) =>
					!list ? (
						<Box
							key={file.id}
							sx={(theme) => ({
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								background: `url("${
									file?.thumbnail
										? file?.thumbnail
										: file?.url
								}") no-repeat center center / cover`,
								p: 2,
								cursor: "pointer" /*
									file?.contentType.startsWith("image/") &&
									!file?.thumbnail
										? "not-allowed" // Changer le curseur pour indiquer qu'il n'est pas sélectionnable
										: "pointer",*/,
								border:
									selectedFile?.url === file.url
										? `4px solid ${theme.vars.palette.primary[400]}`
										: "1px solid #888",
								borderRadius: "10px",
								width: "140px",
								height: "140px",
								overflow: "hidden",
								position: "relative",
							})}
							onClick={() => {
								/*if (
									file.contentType.startsWith("image/") &&
									!file.thumbnail
								) {
									return; // Empêcher la sélection d'une image non optimisée
								}*/
								setSelectedFile(file);
							}}
						>
							{uploading[file.name] ? (
								<LinearProgress
									variant="determinate"
									value={uploading[file.name]}
								/>
							) : (
								<Box
									href={file.url}
									target="_blank"
									rel="noopener noreferrer"
								>
									{!file.contentType.startsWith("image/") && (
										<Stack
											justifyContent="center"
											spacing={1}
											sx={{ textAlign: "center" }}
											alignItems="center"
										>
											<InsertDriveFile />
											<Typography>{file.name}</Typography>
										</Stack>
									)}
								</Box>
							)}
							{!file?.notRemovable && (
								<IconButton
									color="danger"
									sx={{
										position: "absolute",
										top: 0,
										left: 0,
									}}
									onClick={() => handleDelete(file)}
								>
									<Delete />
								</IconButton>
							)}
						</Box>
					) : (
						<ListItem
							key={file.id}
							sx={(theme) => ({
								border:
									selectedFile?.url === file.url
										? `2px solid ${theme.vars.palette.primary[400]}`
										: "2px solid transparent",
								"&:nth-child(even)": {
									background: "#eee",
								},
								"&:hover": {
									cursor:
										file.contentType.startsWith("image/") &&
										!file.thumbnail
											? "not-allowed"
											: "pointer",
									opacity:
										file.contentType.startsWith("image/") &&
										!file.thumbnail
											? 0.7
											: 1,
								},
							})}
							onClick={() => {
								if (
									file.contentType.startsWith("image/") &&
									!file.thumbnail
								) {
									return; // Empêcher la sélection d'une image non optimisée
								}
								setSelectedFile(file);
							}}
							endAction={
								<IconButton
									color="danger"
									onClick={() => handleDelete(file)}
								>
									<Delete />
								</IconButton>
							}
						>
							<Typography
								title={file.name}
								alt={file.name}
								sx={{
									width: "400px",
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
							>
								{!file.contentType.startsWith("image/") ? (
									<InsertDriveFile />
								) : (
									<Image />
								)}{" "}
								{file.name}
							</Typography>
						</ListItem>
					),
				)}
				<Button fullWidth sx={{ mt: 2 }} onClick={handleLoadMore}>
					Voir plus
				</Button>
			</Box>
		</>
	);
};

export default MyFiles;
