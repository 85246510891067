import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Button,
	Stack,
	Tooltip,
	IconButton,
	Switch,
} from "@mui/joy";
import {
	Hotel,
	LocationOn,
	Groups,
	Visibility,
	EventSeat,
	Add,
	VisibilityOff,
	Edit,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Place from "models/places";
import Icons from "assets/icons/Icons";

const PlaceEntity = new Place();
const PlacesTemplateCard = ({
	title,
	image,
	location,
	type,
	admin,
	fromCatalog,
	description,
	publish,
	beds,
	style,
	onEnd,
	nbrSalle,
	id,
	quoteDisabled,
	maxCap,
	nbrSalleTotal,
	visualizeLink,
	setSelect,
	addToFavorite,
}) => {
	const { t } = useTranslation();
	const [published, setPublished] = React.useState(publish);
	const filter = "hue-rotate(160deg)";

	const changeVisibility = (v) => {
		const newValue = !published;
		setPublished(newValue);
		try {
			PlaceEntity.publish = newValue;
			PlaceEntity.updatePlace(id);
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<Box
			sx={(theme) => ({
				height: "100%",
				position: "relative",
				overflow: "hidden",
				opacity: !published ? 0.4 : 1,

				...style,
			})}
			onMouseEnter={() => setSelect && setSelect(id)}
		>
			<Box
				sx={{
					position: "absolute",
					top: 5,
					right: 5,
					background: "white",
					borderRadius: 100,
				}}
			>
				{fromCatalog && (
					<IconButton onClick={() => onEnd(id)} color="primary">
						<Add />
					</IconButton>
				)}

				{addToFavorite}
			</Box>
			<Stack
				alignItems="center"
				justifyContent="space-between"
				spacing={2}
				sx={{
					height: "100%",
				}}
			>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						//flexDirection: { md: "column-reverse", xl: "row" },
						flexDirection: "column-reverse",
						columnGap: "5px",
					}}
				>
					<Stack spacing={1} sx={{ flexGrow: 1, p: 1 }}>
						<Typography level="h5">
							<strong>{title}</strong>
						</Typography>
						<div>
							{location && (
								<Tooltip title={t("location")}>
									<Typography>
										<LocationOn color="primary" />{" "}
										{location}
									</Typography>
								</Tooltip>
							)}

							{beds > 0 && (
								<Tooltip title={t("nombre_couchage_max")}>
									<Typography>
										<Icons
											filter={filter}
											color="#eee"
											name="Pictos Weevapp_Couchage max"
										/>{" "}
										{beds}
									</Typography>
								</Tooltip>
							)}
							{maxCap > 0 && (
								<Tooltip title={t("capacite_max")}>
									<Typography>
										<Icons
											filter={filter}
											color="#eee"
											name="Pictos Weevapp_Capacité max debout"
										/>{" "}
										{maxCap}
									</Typography>
								</Tooltip>
							)}
							{nbrSalle > 0 && (
								<Tooltip title={t("nbr_salle")}>
									<Typography>
										<Icons
											filter={filter}
											color="#eee"
											name="Pictos Weevapp_Nombre de salles"
										/>{" "}
										{nbrSalleTotal}
									</Typography>
								</Tooltip>
							)}
						</div>
					</Stack>
					<Box
						sx={{
							height: "150px",
							borderRadius: "5px 5px 0 0  ",
							width: "100%",
							background: `url("${image}") no-repeat scroll center center / cover`,
						}}
					></Box>
				</Box>
				<Box sx={{ width: "100%", p: 1, borderTop: "1px solid #eee" }}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						{admin && (
							<Box>
								<Switch
									checked={published}
									onChange={changeVisibility}
									startDecorator={
										<Visibility
											color={
												published
													? "primary"
													: "neutral"
											}
										/>
									}
								/>
							</Box>
						)}
						<Stack
							sx={{
								mb: 1,
								".MuiButton-root": {
									pl: 4,
									pr: 4,
								},
							}}
							direction="row"
							spacing={1}
						>
							{!quoteDisabled && <Button>{t("quote")}</Button>}
							<Button
								variant="solid"
								component={Link}
								to={visualizeLink}
							>
								{t("see")}
							</Button>
							{admin && (
								<Tooltip title={t("admin_edit")}>
									<IconButton
										variant="outlined"
										component={Link}
										to={`/admin/places/${id}`}
									>
										<Edit />
									</IconButton>
								</Tooltip>
							)}
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Box>
	);
};

export default PlacesTemplateCard;
PlacesTemplateCard.propTypes = {
	title: PropTypes.string,
	image: PropTypes.string,
	location: PropTypes.string,
	type: PropTypes.string,
	description: PropTypes.string,
	beds: PropTypes.string,
	id: PropTypes.string,
	style: PropTypes.object,
	visualizeLink: PropTypes.string,
	quoteDisabled: PropTypes.bool,
	selected: PropTypes.bool,
};
PlacesTemplateCard.defaultProps = {
	title: "",
	image: "",
	selected: false,
	visualizeLink: "/",
	location: "",
	type: "",
	description: "",
	beds: "",
	quoteDisabled: false,
	id: "",
	style: { width: "100%" },
};
