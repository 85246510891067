import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { AdvancedDateRange } from "UI";

const ProjectDates = ({ control, setValue, getValues }) => {
	const { t } = useTranslation();

	return (
		<>
			<AdvancedDateRange
				setVal={setValue}
				control={control}
				withPortal={false}
				autoSave
				names={{
					dateStopped: "project_event_date_stopped",
					datePeriod: "project_event_date_period",
					dateDuration: "project_event_day_duration",
					dateStart: "project_event_start_date",
					dateEnd: "project_event_end_date",
				}}
				getVal={getValues}
			/>
		</>
	);
};

export default ProjectDates;
ProjectDates.propTypes = {};
ProjectDates.defaultProps = {};
