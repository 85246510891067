import React from "react";
import { Box, Typography, IconButton, Chip, Button } from "@mui/joy";
import { Delete, ArrowForwardIos, Add, Remove } from "@mui/icons-material";
import PropTypes from "prop-types";

const OptionItemCardCatalog = ({
  onDelete,
  title,
  description,
  extraInfo,
  image,
  id,
  actions,
  showDetails,
  onSelect,
  selected,
  hashtags,
  sx,
}) => {
  // Gestion de l'image qui peut être un objet ou une URL directe
  const resolvedImage =
    image?.thumb || image?.full || image || "https://via.placeholder.com/100";

  return (
    <Box sx={{ width: "200px", height: "fit-content", position: "relative" }}>
      <Box sx={{ position: "absolute", width: "100%" }}>
        <IconButton
          sx={{ position: "absolute", right: "-8px", top: "-8px" }}
          color={!selected ? "primary" : "danger"}
          variant="solid"
          onClick={() => onSelect && onSelect()}
        >
          {!selected ? <Add /> : <Remove />}
        </IconButton>
      </Box>
      <Box
        sx={{
          borderRadius: 15,
          minWidth: "200px",
          mb: 2,
          height: "200px",
          background: `url("${resolvedImage}") no-repeat center center / cover`,
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          rowGap: 1,
        }}
      >
        <Typography sx={{ fontSize: "18px" }} color="primary">
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "2px",
            flexWrap: "wrap",
          }}
        >
          {hashtags && hashtags?.map((hashtag) => <Chip>#{hashtag}</Chip>)}
        </Box>
        {description && (
          <Typography
            sx={{
              height: "40px",
              fontSize: "12px",
              overflow: "hidden",
            }}
            paragraph
          >
            {description}
          </Typography>
        )}
        {extraInfo && (
          <Typography
            sx={{
              height: "40px",
              fontSize: "14px",
              overflow: "hidden",
            }}
            paragraph
            color={"secondary"}
          >
            {extraInfo.toUpperCase()}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px",
        }}
      >
        {actions}

        {onDelete && (
          <IconButton color="primary" onClick={() => onDelete(id)}>
            <Delete />
          </IconButton>
        )}
        {showDetails && (
          <IconButton color="primary" variant="solid" onClick={showDetails}>
            <ArrowForwardIos />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

OptionItemCardCatalog.propTypes = {
  onDelete: PropTypes.func, // Fonction appelée lors de la suppression
  title: PropTypes.string.isRequired, // Titre principal de l'élément
  description: PropTypes.string, // Description ou texte secondaire
  extraInfo: PropTypes.string, // Information supplémentaire (ex. prix)
  image: PropTypes.oneOfType([
    PropTypes.string, // URL directe
    PropTypes.shape({
      thumb: PropTypes.string, // URL de la version miniature
      full: PropTypes.string, // URL de la version complète
    }),
  ]), // Image (URL ou objet)
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Identifiant unique
  actions: PropTypes.node, // Actions supplémentaires (ex. boutons personnalisés)
  sx: PropTypes.object, // Styles supplémentaires
};

OptionItemCardCatalog.defaultProps = {
  description: "",
  extraInfo: "",
  image: null,
  actions: null,
  onDelete: null,
  sx: {},
};

export default OptionItemCardCatalog;
