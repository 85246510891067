import React, { useEffect, useState } from "react";
import {
  Box,
  ListItemDecorator,
  ListItemContent,
  ListItemButton,
  ListItem,
  Divider,
  List,
} from "@mui/joy";
import { Logo } from "./partials";
import {
  DashboardOutlined,
  Person,
  Group,
  AdminPanelSettings,
  EventOutlined,
  Domain,
  Notifications,
  FormatListBulletedOutlined,
  Mail,
  PermMedia,
  AccountTree,
  AccountCircleOutlined,
  PowerSettingsNewOutlined,
  Handshake,
  Celebration,
  Diversity1,
  KeyboardArrowRight,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import Helpers from "../../../../../utils/Helpers";
import useGlobalContainer from "stores/Global";
import useAuthProfile from "../../../../../containers/authentification/hooks/useAuthProfile";

const MENU_CONFIG = {
  default: [
    { label: "dashboard", path: "/dashboard", Icon: DashboardOutlined },
    { label: "my_projects", path: "/projects", Icon: EventOutlined },
    { label: "our_partners", path: "/partners", Icon: Handshake },
    { label: "media_lib", path: "?media_lib=true", Icon: PermMedia },
    {
      label: "services",
      path: "/services",
      Icon: FormatListBulletedOutlined,
    },
  ],
  partner: [
    { label: "dashboard", path: "/dashboard", Icon: DashboardOutlined },
    { label: "my_programs", path: "/event/list", Icon: EventOutlined },
    { label: "my_models", path: "/event/models", Icon: EventOutlined },
    { label: "my_places", path: "/places", Icon: Domain },
    { label: "media_lib", path: "?media_lib=true", Icon: PermMedia },
  ],
  admin: [
    { label: "dashboard", path: "/admin/", Icon: DashboardOutlined },
    { label: "admin_projects", path: "/admin/projects", Icon: AccountTree },
    { label: "admin_users", path: "/admin/users", Icon: Group },
    { label: "admin_events", path: "/admin/events", Icon: EventOutlined },
    { label: "admin_places", path: "/admin/places", Icon: Domain },
    { label: "admin_groups", path: "/admin/groups", Icon: Domain },
    { label: "admin_request", path: "/admin/requests", Icon: Mail },
    { label: "animations", path: "/admin/animations", Icon: Celebration },
    {
      label: "team-buildings",
      path: "/admin/team-buildings",
      Icon: Diversity1,
    },
    {
      label: "admin_notifications",
      path: "/admin/notifications",
      Icon: Notifications,
    },
    { label: "media_lib", path: "?media_lib=true", Icon: PermMedia },
  ],
};

const getSubMenuItems = (admin, isAdmin, logout) => [
  { label: "my_account", path: "/account", Icon: AccountCircleOutlined },
  {
    label: "disconnect",
    path: "/#",
    action: logout,
    Icon: PowerSettingsNewOutlined,
  },
  ...(admin
    ? [{ label: "back_to_user_mode", path: "/", Icon: Person }]
    : isAdmin
    ? [{ label: "admin_mode", path: "/admin", Icon: AdminPanelSettings }]
    : []),
];

const Menu = ({ style, openMenu, setOpenMenu, isPartner, admin, isAdmin }) => {
  const { logout } = useAuthProfile();
  const { t } = useTranslation();
  const { mode } = useGlobalContainer.useContainer();
  const location = useLocation();
  const rootLocation = Helpers.uriParts(location.pathname, 0);

  const [menuItems, setMenuItems] = useState(
    admin
      ? MENU_CONFIG.admin
      : isPartner
      ? MENU_CONFIG.partner
      : MENU_CONFIG.default
  );

  useEffect(() => {
    setMenuItems(
      admin
        ? MENU_CONFIG.admin
        : isPartner
        ? MENU_CONFIG.partner
        : MENU_CONFIG.default
    );
  }, [admin, isPartner]);

  const toggleItemOpen = (index) => {
    setMenuItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index].open = !updatedItems[index].open;
      return updatedItems;
    });
  };

  const renderMenuItem = (item, index) => (
    <ListItem
      key={index}
      sx={{ mb: 1, flexWrap: "wrap", flexDirection: "column" }}
    >
      <ListItemButton
        onClick={() => toggleItemOpen(index)}
        variant={location.pathname === item.path ? "solid" : "plain"}
        selected={location.pathname === item.path}
        component={NavLink}
        to={item.path}
        sx={(theme) => ({
          width: "100%",
        })}
      >
        <ListItemDecorator>{item.Icon && <item.Icon />}</ListItemDecorator>
        <ListItemContent>{t(item.label)}</ListItemContent>
        {item.childrens &&
          (item.open ? <KeyboardArrowDown /> : <KeyboardArrowRight />)}
      </ListItemButton>

      {item.childrens && item.open && (
        <List sx={{ p: 2 }}>
          {item.childrens.map((child, childIndex) => (
            <ListItem key={childIndex} sx={{ mb: 1 }}>
              <ListItemButton
                component={NavLink}
                to={child.path}
                selected={location.pathname === child.path}
                sx={{
                  color: location.pathname === child.path ? "bold" : "inherit",
                }}
              >
                <ListItemContent>{t(child.label)}</ListItemContent>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </ListItem>
  );

  const subMenuItems = getSubMenuItems(admin, isAdmin, logout);

  return (
    <Box style={style} sx={{ background: "white" }}>
      <Logo />
      <List sx={{ p: 2, height: "calc(100vh - 322px)", overflow: "auto" }}>
        {menuItems.map(renderMenuItem)}
      </List>
      <Box sx={{ position: "absolute", width: "100%", bottom: "3px" }}>
        <Divider sx={{ width: "60%", margin: "0 auto" }} />
        <List sx={{ p: 2 }}>
          {subMenuItems.map((item, index) => (
            <ListItem key={index}>
              <ListItemButton
                variant={location.pathname === item.path ? "solid" : "plain"}
                selected={location.pathname === item.path}
                component={NavLink}
                to={item.path}
                onClick={item.action}
                sx={{ width: "100%" }}
              >
                <ListItemDecorator>
                  {item.Icon && <item.Icon />}
                </ListItemDecorator>
                <ListItemContent>{t(item.label)}</ListItemContent>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default Menu;
