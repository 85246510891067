import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/joy";
import PropTypes from "prop-types";

const AutocompleteSimple = ({
  placeholder,
  label,
  fetchOptions,
  onSelect,
  optionLabelKey = "label",
  optionValueKey = "value",
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFetch = async (searchTerm) => {
    setLoading(true);
    try {
      const fetchedOptions = await fetchOptions(searchTerm);
      const formattedOptions = fetchedOptions.map((option) => ({
        label: option[optionLabelKey],
        value: option[optionValueKey],
      }));
      setOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Autocomplete
      placeholder={placeholder}
      options={options}
      loading={loading}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value}
      onInputChange={(event, value) => handleFetch(value)}
      onChange={(event, value) => onSelect(value)}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
    />
  );
};

AutocompleteSimple.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  fetchOptions: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  optionLabelKey: PropTypes.string,
  optionValueKey: PropTypes.string,
};

AutocompleteSimple.defaultProps = {
  placeholder: "",
  label: "",
  optionLabelKey: "label",
  optionValueKey: "value",
};

export default AutocompleteSimple;
