import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { OptionItemCardCatalog } from "UI";

const AnimationItem = ({
	animation_name,
	animation_description,
	animation_price,
	animation_featured_image,
	animation_hashtags,

	id,
	onSelect,
	selected,
}) => {
	const { t } = useTranslation();

	return (
		<OptionItemCardCatalog
			id={id}
			title={animation_name}
			description={animation_description}
			hashtags={animation_hashtags}
			extraInfo={animation_price}
			image={animation_featured_image}
			selected={selected}
			onSelect={onSelect}
		/>
	);
};

export default AnimationItem;
AnimationItem.propTypes = {};
AnimationItem.defaultProps = {};
