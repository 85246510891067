import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { Hits } from "react-instantsearch";
import { PlacesTemplateCard } from "UI/Grids";
import { AddToFavorite } from "Common/favorites";
import useAuthContainer from "stores/Auth";

const PlaceCardHit = ({
	t,
	description,
	pays,
	image,
	nom,
	selected,
	region,
	ville,
	type_lieu,
	code_postal,
	admin,
	rooms,
	objectID,
	nombre_couchage_max,
	capacite_salle_max,
	nbr_salle_plus_50,
	nbr_salle_minus_50,
	publish,
	projectId,
	projectPlaces,
	onEnd,
	fromCatalog,
}) => (
	<PlacesTemplateCard
		maxCap={capacite_salle_max.reduce((max, config) => {
			return config.capacite > max ? config.capacite : max;
		}, 0)}
		nbrSalle={rooms}
		nbrSalleTotal={
			parseInt(nbr_salle_plus_50 || 0) + parseInt(nbr_salle_minus_50 || 0)
		}
		admin={admin}
		setSelect={() => null}
		title={nom}
		selectedID={selected}
		image={image}
		location={`${region ? region : ""} ${pays ? `,${pays}` : ""}`}
		type={type_lieu}
		description={description}
		beds={nombre_couchage_max}
		id={objectID}
		quoteDisabled
		publish={publish}
		fromCatalog={fromCatalog}
		onEnd={onEnd}
		addToFavorite={
			!fromCatalog && (
				<AddToFavorite
					id={objectID}
					featured_image={image}
					region={region}
					type_lieu={type_lieu}
					code_postal={code_postal}
					nom={nom}
					pays={pays}
					ville={ville}
					projectId={projectId}
					projectPlaces={projectPlaces}
					onEnd={onEnd}
					type={"places"}
					iconButton={true}
				/>
			)
		}
		visualizeLink={`/places/view/${objectID}`}
	/>
);

const Results = ({ showMap, projectId, projectPlaces, onEnd, fromCatalog }) => {
	const { t } = useTranslation();
	const { userAccount } = useAuthContainer.useContainer();

	return (
		<>
			<Box
				sx={{
					mt: 2,
					mb: 2,
					p: 2,
					".ais-Hits": {},
					".ais-Hits-list": {
						display: "grid",
						gap: 2,
						gridTemplateColumns: !showMap
							? {
									md: "1fr 1fr 1fr 1fr",
									xl: "1fr 1fr 1fr 1fr 1fr",
							  }
							: {
									lg: "1fr 1fr",
									xl: "1fr 1fr 1fr",
							  },
						".ais-Hits-item": {
							height: "417px",
							p: 0,
							display: "flex",
							overflow: "hidden",
							alignItems: "start",

							width: "100%",
						},
					},
				}}
			>
				<Hits
					hitComponent={({ hit }) => {
						return (
							<PlaceCardHit
								fromCatalog={fromCatalog}
								projectId={projectId}
								projectPlaces={projectPlaces}
								onEnd={onEnd}
								admin={userAccount?.role === "admin"}
								key={hit.objectID}
								t={t}
								{...hit}
							/>
						);
					}}
				/>
			</Box>
		</>
	);
};

export default Results;
Results.propTypes = {
	showMap: PropTypes.bool,
};
Results.defaultProps = {};
