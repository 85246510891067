import FirestoreService from "services/firestoreServices";
import Helpers from "utils/Helpers";
import { where, orderBy } from "firebase/firestore";

/**
 * Creates an instance of the TeamBuilding class.
 * @constructor
 * @param {string} params.team_building_name
 * @param {string} params.team_building_type
 * @param {string} params.team_building_rse
 * @param {string} params.team_building_description
 * @param {string} params.team_building_example
 * @param {Array} params.team_building_hashtags
 * @param {Number} params.team_building_min_pers
 * @param {Number} params.team_building_max_pers
 * @param {Number} params.team_building_price
 * @param {Number} params.team_building_price_type
 * @param {Number} params.team_building_featured_image
 * @param {Number} params.team_building_prep_time
 * @param {Number} params.team_building_duration
 * @param {string} params.created_at
 * @param {Object} params.modified_at - Date de modification
 */

class TeamBuilding {
    constructor(params) {
        Object.assign(this, params);
        this.modified_at = new Date();

        this.firestoreService = new FirestoreService("team_buildings");
    }

    async getTeamBuildings(clause, onUpdate) {
        try {
            const team_building = await this.firestoreService.getDocuments(
                clause,
                true,
                onUpdate,
            );
            return team_building;
        } catch (error) {
            console.error("Failed to fetch team_building:", error);
            throw error;
        }
    }

    async getTeamBuildingsPagination(lastVisible = null, constraints = []) {
        try {
            const result = await this.firestoreService.getDocuments(
                constraints,
                false,
                null,
                20,
                lastVisible,
            );
            if (lastVisible) {
                return {
                    documents: result.documents,
                    lastVisible: result.lastVisible,
                    no_more_data: result.no_more_data,
                };
            } else {
                return result;
            }
        } catch (error) {
            console.error("Failed to fetch events:", error);
            throw error;
        }
    }

    async getTeamBuilding(id) {
        try {
            const team_building = await this.firestoreService.getDocument(id);
            return team_building;
        } catch (error) {
            console.error("Failed to fetch team_building:", error);
            throw error;
        }
    }

    async addTeamBuilding() {
        try {
            const team_building = await this.firestoreService.addDocument(
                this.toJSON(),
            );
            return { id: team_building.id, ...this.toJSON() };
        } catch (error) {
            console.error("Failed to add team_building:", error);
            throw error;
        }
    }

    async deleteTeamBuilding(id) {
        try {
            await this.firestoreService.deleteDocument(id);
        } catch (error) {
            console.error("Failed to delete team_building:", error);
            throw error;
        }
    }

    async updateTeamBuilding(id) {
        try {
            const updatedTeamBuilding =
                await this.firestoreService.updateDocument(
                    id,
                    this.toJSON(),
                    [],
                );
            return updatedTeamBuilding;
        } catch (error) {
            console.error("Failed to update team_building:", error);
            throw error;
        }
    }

    toJSON() {
        let data = {};
        const properties = {
            modified_at: this.modified_at,
            created_at: this.created_at,
            team_building_name: this.team_building_name,
            team_building_type: this.team_building_type,
            team_building_rse: this.team_building_rse,
            team_building_description: this.team_building_description,
            team_building_example: this.team_building_example,
            team_building_hashtags: this.team_building_hashtags,
            team_building_min_pers: this.team_building_min_pers,
            team_building_max_pers: this.team_building_max_pers,
            team_building_featured_image: this.team_building_featured_image,
            team_building_price: this.team_building_price,
            team_building_price_type: this.team_building_price_type,
            team_building_prep_time: this.team_building_prep_time,
            team_building_duration: this.team_building_duration,
        };

        // Add only properties that are not undefined
        for (let key in properties) {
            if (properties[key] !== undefined) {
                data[key] = properties[key];
            }
        }

        return data;
    }
}

export default TeamBuilding;
