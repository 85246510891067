import React from "react";
import { PartnerModelHeader } from "./partials";
import { BOLayout } from "../../../../components/layouts";
import { useParams, useHistory, Link } from "react-router-dom";
import useEventStore from "../../EventStore";
import { Box, Button } from "@mui/joy";
import useAuthContainer from "stores/Auth";
import { EditEventGeneralForm } from "forms/events";
import Event from "models/events";
import Place from "models/places";
import { VisualEditor } from "Common";
import { useTranslation } from "react-i18next";

const General = ({ props }) => {
	const history = useHistory();
	let { eventID } = useParams();
	let { t } = useTranslation();
	const { docStatus } = useEventStore.useContainer();
	const { userAccount } = useAuthContainer.useContainer();
	const [datas, setDatas] = React.useState(null);
	const [place, setPlace] = React.useState(null);
	const EventEntity = new Event();
	const PlaceEntity = new Place();

	const fetchEvent = async () => {
		const event = await EventEntity.getEvent(eventID);
		if (event) {
			if (event?.placeId || event?.fromPlaceId) {
				const _place = await PlaceEntity.getPlace(
					event?.placeId || event?.fromPlaceId,
				);
				if (_place) {
					setPlace(_place);
				}
			}
			setDatas(event);
		}
	};

	React.useEffect(() => {
		fetchEvent();
	}, []);

	return (
		<BOLayout
			documentID={eventID}
			isDocumentStatusMonitoring
			documentStatus={docStatus}
			showEventMenu
			title="general"
		>
			{datas?.project_id && (
				<Button
					sx={{ mb: 1 }}
					component={Link}
					to={`/projects/${datas?.project_id}/dashboard`}
				>
					{t("back_to_project")}
				</Button>
			)}
			<Box sx={{ gap: 4, display: "flex", flexDirection: "row" }}>
				<Box sx={{ flexGrow: 1 }}>
					{!datas?.fromPlaceId && (
						<PartnerModelHeader
							event={datas}
							show={userAccount?.isApproved}
							placeID={datas?.placeId}
						/>
					)}

					{datas && (
						<EditEventGeneralForm
							formDatas={datas}
							setFormDatas={setDatas}
							place={place}
						/>
					)}
				</Box>
				<Box>
					<VisualEditor
						src={`${process.env.REACT_APP_base_url}/my_event/${eventID}`}
					/>
				</Box>
			</Box>
		</BOLayout>
	);
};

export default General;
