import React from "react";
import { FormSeparator } from "UI";
import { Input, FormControl, FormHelperText } from "@mui/joy";
import { useTranslation } from "react-i18next";

const ProjectName = ({ register, error }) => {
	const { t } = useTranslation();
	return (
		<FormSeparator required title="project_name">
			<FormControl>
				<Input
					sx={{ mb: 1 }}
					{...register("project_name", {
						required: t("project_name_required"),
					})}
					type="text"
					placeholder={t("project_name")}
				/>
				{error && <FormHelperText>{error.message}</FormHelperText>}
			</FormControl>
		</FormSeparator>
	);
};

export default ProjectName;
