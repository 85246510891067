import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Modal, ModalDialog, ModalOverflow } from "@mui/joy";
import PropTypes from "prop-types";
import { EditTeamBuildingForm } from "forms/admin/team_buildings";

const CreateTeamBuildingModal = ({ open, close, datas }) => {
	const { t } = useTranslation();

	return (
		<Modal onClose={close} open={open}>
			<ModalOverflow>
				<ModalDialog sx={{ width: "700px" }}>
					<EditTeamBuildingForm
						onEnd={close}
						datas={datas}
						mode={datas ? "edition" : "creation"}
					/>
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default CreateTeamBuildingModal;
CreateTeamBuildingModal.propTypes = {};
CreateTeamBuildingModal.defaultProps = {};
