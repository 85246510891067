import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { ProjectsList } from "./views";

const Projects = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Switch>
        <Route exact path="/admin/projects">
          <ProjectsList />
        </Route>
      </Switch>
    </>
  );
};

export default Projects;
Projects.propTypes = {};
Projects.defaultProps = {};
