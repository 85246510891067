import React from "react";
import { Box, Typography, IconButton } from "@mui/joy";
import { Delete, ArrowForwardIos } from "@mui/icons-material";
import PropTypes from "prop-types";

const OptionItemCard = ({
  onDelete,
  title,
  description,
  extraInfo,
  image,
  id,
  actions,
  showDetails,
  onSelect,
  selected,
  sx,
}) => {
  // Gestion de l'image qui peut être un objet ou une URL directe
  const resolvedImage =
    image?.thumb || image?.full || image || "https://via.placeholder.com/100";

  return (
    <Box
      onClick={onSelect && onSelect}
      sx={{
        background: "#F1F1F1",
        display: "flex",
        flexDirection: "row",
        p: 1,
        borderRadius: 15,
        cursor: onSelect && "pointer",
        border: selected && "2px solid #444",
        columnGap: 2,
        width: "430px",
        ...sx,
      }}
    >
      <Box
        sx={{
          borderRadius: 15,
          minWidth: "100px",
          height: "100px",
          background: `url("${resolvedImage}") no-repeat center center / cover`,
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "2px",
          flex: 1,
        }}
      >
        <Typography sx={{ fontSize: "18px" }} color="primary">
          {title}
        </Typography>
        {description && (
          <Typography
            sx={{
              height: "40px",
              fontSize: "12px",
              overflow: "hidden",
            }}
            paragraph
          >
            {description}
          </Typography>
        )}
        {extraInfo && (
          <Typography
            sx={{
              height: "40px",
              fontSize: "14px",
              overflow: "hidden",
            }}
            paragraph
            color={"secondary"}
          >
            {extraInfo.toUpperCase()}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px",
        }}
      >
        {actions}

        {onDelete && (
          <IconButton color="primary" onClick={() => onDelete(id)}>
            <Delete />
          </IconButton>
        )}
        {showDetails && (
          <IconButton color="primary" variant="solid" onClick={showDetails}>
            <ArrowForwardIos />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

OptionItemCard.propTypes = {
  onDelete: PropTypes.func, // Fonction appelée lors de la suppression
  title: PropTypes.string.isRequired, // Titre principal de l'élément
  description: PropTypes.string, // Description ou texte secondaire
  extraInfo: PropTypes.string, // Information supplémentaire (ex. prix)
  image: PropTypes.oneOfType([
    PropTypes.string, // URL directe
    PropTypes.shape({
      thumb: PropTypes.string, // URL de la version miniature
      full: PropTypes.string, // URL de la version complète
    }),
  ]), // Image (URL ou objet)
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Identifiant unique
  actions: PropTypes.node, // Actions supplémentaires (ex. boutons personnalisés)
  sx: PropTypes.object, // Styles supplémentaires
};

OptionItemCard.defaultProps = {
  description: "",
  extraInfo: "",
  image: null,
  actions: null,
  onDelete: null,
  sx: {},
};

export default OptionItemCard;
