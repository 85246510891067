import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Select, Option, Button, FormLabel, FormControl } from "@mui/joy";
import { useForm } from "react-hook-form";
import { AutocompleteSimple } from "UI";
import User from "models/user";
import PropTypes from "prop-types";

const UserInstance = new User();

const statusList = ["waiting", "active", "canceled"];
const style = {
  display: "flex",
  flexDirection: "row",
  gap: "11px",
  alignItems: "end",
  background: "white",
  padding: "13px",
};

const Filters = ({ filter, setFilter, loading }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      uid: filter?.uid,
      status: filter?.status,
    },
  });

  const fetchUsers = async (searchTerm) => {
    if (!searchTerm) return [];

    const result = await UserInstance.searchUsersByName(searchTerm);
    return result.map((user) => ({
      label: `${user.email}`,
      value: user.id,
    }));
  };

  return (
    <Box>
      <form
        onSubmit={handleSubmit((data) => {
          console.log(data);

          setFilter(data);
        })}
      >
        <Box sx={style}>
          <FormControl>
            <FormLabel>{t("status")}</FormLabel>
            <Select defaultValue={getValues("status")} {...register("status")}>
              <Option key={"all"} value={null}>
                {t("all")}
              </Option>
              {statusList.map((status) => (
                <Option key={status} value={status}>
                  {t(status)}
                </Option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>{t("email")}</FormLabel>
            <AutocompleteSimple
              placeholder={t("select_a_project")}
              label="Select Project"
              fetchOptions={fetchUsers}
              onSelect={(value) => {
                if (value) {
                  setValue("uid", value.value);
                } else {
                  setValue("uid", null);
                }
              }}
            />{" "}
          </FormControl>

          <Button
            loading={loading}
            disabled={loading}
            type="submit"
            color="primary"
          >
            {t("search")}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default Filters;
Filters.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
Filters.defaultProps = {
  loading: false,
};
