import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Modal, ModalOverflow, ModalDialog } from "@mui/joy";
import PropTypes from "prop-types";
import { ListAnimations } from "forms/animations";
import { useParams } from "react-router-dom";
import Project from "models/projects";

const ProjectEntity = new Project();

const AnimationsModal = ({ open, close, datas, setDatas }) => {
	const { t } = useTranslation();
	const { projectID } = useParams();

	const end = (datas) => {
		// Crée une copie des données reçues avec un ID
		const receivedData = { ...datas, id: datas.objectID };
		delete receivedData.objectID;

		setDatas((prev) => {
			const selectedAnimations = prev?.project_animations || [];

			// Vérifie si l'élément existe déjà dans le tableau
			const exists = selectedAnimations.some(
				(selected) => selected.id === receivedData.id,
			);

			// Crée le tableau mis à jour
			const updatedAnimations = exists
				? selectedAnimations.filter((s) => s.id !== receivedData.id) // Supprimer
				: [...selectedAnimations, receivedData]; // Ajouter

			// Met à jour ProjectEntity avant de retourner l'état mis à jour
			ProjectEntity.project_animations = updatedAnimations;
			ProjectEntity.updateProject(projectID);

			console.log("Updated Animations:", updatedAnimations); // Debug

			return { ...prev, project_animations: updatedAnimations };
		});
	};

	return (
		<Modal open={open} onClose={close}>
			<ModalOverflow>
				<ModalDialog sx={{ minWidth: "400px" }}>
					<ListAnimations
						selected={datas?.project_animations || []}
						onEnd={end}
					/>
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default AnimationsModal;
AnimationsModal.propTypes = {};
AnimationsModal.defaultProps = {};
