import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Input,
	Textarea,
	Select,
	Option,
	FormControl,
	Divider,
	FormLabel,
	Button,
} from "@mui/joy";
import { Image } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import TagsInput from "react-tagsinput";
import { AddSimpleImage } from "UI";
import TeamBuilding from "models/team_buildings";
import teamBuildingTypes from "datas/team_buildings/team_building_type_list.json";

const TeamBuildingEntity = new TeamBuilding();

const EditTeamBuildingForm = ({ mode = "creation", onEnd, datas }) => {
	console.log(datas);
	const { t } = useTranslation();
	const [saving, setSaving] = React.useState(false);
	const { setValue, watch, register, control, handleSubmit, getValues } =
		useForm({
			defaultValues: datas || {
				team_building_hashtags: [],
			},
		});
	let tags = watch("team_building_hashtags");

	const save = async (d) => {
		setSaving(true);
		try {
			Object.assign(TeamBuildingEntity, d);

			if (mode === "creation") {
				const team_building =
					await TeamBuildingEntity.addTeamBuilding();
			}
			if (mode === "edition") {
				const team_building =
					await TeamBuildingEntity.updateTeamBuilding(datas?.id);
			}

			onEnd && onEnd();
		} catch (e) {
			console.error("error saving team_building", e.message);
		}

		setSaving(false);
	};
	return (
		<>
			<form onSubmit={handleSubmit(save)}>
				<Box
					sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}
				>
					<FormControl>
						<FormLabel>{t("team_building_name")}</FormLabel>
						<Input
							type="text"
							{...register("team_building_name")}
							placeholder={t("team_building_name")}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>{t("team_building_type")}</FormLabel>
						<Select
							defaultValue={getValues("team_building_type")}
							onChange={(e, newValue) =>
								setValue("team_building_type", newValue)
							}
						>
							{teamBuildingTypes.map((type) => (
								<Option key={type} value={type}>
									{t(type)}
								</Option>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<FormLabel>{t("team_building_price")}</FormLabel>
						<Input
							{...register("team_building_price")}
							startDecorator={"€"}
							endDecorator={
								<>
									<Divider orientation="vertical" />
									<Select
										defaultValue={
											getValues(
												"team_building_price_type",
											) || "per_person"
										}
										onChange={(e, newValue) =>
											setValue(
												"team_building_price_type",
												newValue,
											)
										}
										slotProps={{
											listbox: {
												variant: "outlined",
											},
										}}
										sx={{
											mr: -1.5,
											"&:hover": {
												bgcolor: "transparent",
											},
										}}
									>
										{["per_person", "total"].map(
											(price_type) => (
												<Option value={price_type}>
													{t(price_type)}
												</Option>
											),
										)}
									</Select>
								</>
							}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>{t("team_building_description")}</FormLabel>
						<Textarea
							minRows={3}
							type="text"
							{...register("team_building_description")}
							placeholder={t("team_building_description")}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>{t("team_building_rse")}</FormLabel>
						<Textarea
							minRows={3}
							type="text"
							{...register("team_building_rse")}
							placeholder={t("team_building_rse")}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>{t("team_building_example")}</FormLabel>
						<Textarea
							minRows={3}
							type="text"
							{...register("team_building_example")}
							placeholder={t("team_building_example")}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>{t("team_building_min_pers")}</FormLabel>
						<Input
							type="text"
							{...register("team_building_min_pers")}
							placeholder={t("team_building_min_pers")}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>{t("team_building_max_pers")}</FormLabel>
						<Input
							type="text"
							{...register("team_building_max_pers")}
							placeholder={t("team_building_max_pers")}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>{t("team_building_prep_time")}</FormLabel>
						<Input
							type="text"
							{...register("team_building_prep_time")}
							placeholder={t("team_building_prep_time")}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>{t("team_building_duration")}</FormLabel>
						<Input
							type="text"
							{...register("team_building_duration")}
							placeholder={t("team_building_duration")}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>Tags</FormLabel>
						<TagsInput
							inputProps={{
								className: "react-tagsinput-input",
								placeholder: "Ajouter un tag",
							}}
							value={tags}
							onChange={(tag) =>
								setValue("team_building_hashtags", tag)
							}
						/>
					</FormControl>
					<FormControl>
						<AddSimpleImage
							style={{
								maxWidth: "700px",
								width: "100%",
								height: "300px",
							}}
							label={t("team_building_featured_image")}
							icon={<Image />}
							remove={() =>
								setValue("team_building_featured_image", null)
							}
							action={(img) =>
								setValue(
									"team_building_featured_image",
									img?.thumb || img?.full || img,
								)
							}
							image={watch("team_building_featured_image")}
						/>
					</FormControl>
				</Box>

				<Box sx={{ mt: 2 }}>
					<Button disabled={saving} loading={saving} type="submit">
						{t("save")}
					</Button>
				</Box>
			</form>
		</>
	);
};

export default EditTeamBuildingForm;
EditTeamBuildingForm.propTypes = {};
EditTeamBuildingForm.defaultProps = {};
