import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { PlaceSearch } from "containers/places/views";

const ProjectAddPlaceForm = ({ projectId, onEnd, places }) => {
	const { t } = useTranslation();

	return (
		<>
			<PlaceSearch
				projectId={projectId}
				projectPlaces={places}
				onEnd={onEnd}
			/>
		</>
	);
};

export default ProjectAddPlaceForm;
ProjectAddPlaceForm.propTypes = {};
ProjectAddPlaceForm.defaultProps = {};
