import React from "react";
import { Textarea, Typography, FormControl, FormHelperText } from "@mui/joy";
import { Group } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const ProjectDetails = ({ register }) => {
	const { t } = useTranslation();
	return (
		<FormControl>
			<Textarea
				type="text"
				minRows={5}
				placeholder={t("project_details")}
				{...register("project_details")}
			/>
		</FormControl>
	);
};

export default ProjectDetails;
