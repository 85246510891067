import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Input,
	Textarea,
	Select,
	Option,
	FormControl,
	Divider,
	FormLabel,
	Button,
} from "@mui/joy";
import { Image } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import TagsInput from "react-tagsinput";
import { AddSimpleImage } from "UI";
import Animation from "models/animations";
import animationTypes from "datas/animations/animation_type_list.json";

const AnimationEntity = new Animation();

const EditAnimationForm = ({ mode = "creation", onEnd, datas }) => {
	const { t } = useTranslation();
	const [saving, setSaving] = React.useState(false);
	const { setValue, watch, register, control, handleSubmit, getValues } =
		useForm({
			defaultValues: datas || {
				animation_hashtags: [],
			},
		});

	let tags = watch("animation_hashtags");
	console.log(datas);
	const save = async (d) => {
		setSaving(true);
		try {
			Object.assign(AnimationEntity, d);

			if (mode === "creation") {
				const animation = await AnimationEntity.addAnimation();
			}
			if (mode === "edition") {
				const animation = await AnimationEntity.updateAnimation(
					datas?.id,
				);
			}

			onEnd && onEnd();
		} catch (e) {
			console.error("error saving animation", e.message);
		}

		setSaving(false);
	};
	return (
		<>
			<form onSubmit={handleSubmit(save)}>
				<Box
					sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}
				>
					<FormControl>
						<FormLabel>{t("animation_name")}</FormLabel>
						<Input
							type="text"
							{...register("animation_name")}
							placeholder={t("animation_name")}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>{t("animation_type")}</FormLabel>
						<Select
							defaultValue={getValues("animation_type")}
							onChange={(e, newValue) =>
								setValue("animation_type", newValue)
							}
						>
							{animationTypes.map((type) => (
								<Option key={type} value={type}>
									{t(type)}
								</Option>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<FormLabel>{t("animation_price")}</FormLabel>
						<Input
							{...register("animation_price")}
							startDecorator={"€"}
							endDecorator={
								<>
									<Divider orientation="vertical" />
									<Select
										defaultValue={
											getValues("animation_price_type") ||
											"per_person"
										}
										onChange={(e, newValue) =>
											setValue(
												"animation_price_type",
												newValue,
											)
										}
										slotProps={{
											listbox: {
												variant: "outlined",
											},
										}}
										sx={{
											mr: -1.5,
											"&:hover": {
												bgcolor: "transparent",
											},
										}}
									>
										{["per_person", "total"].map(
											(price_type) => (
												<Option value={price_type}>
													{t(price_type)}
												</Option>
											),
										)}
									</Select>
								</>
							}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>{t("animation_description")}</FormLabel>
						<Textarea
							minRows={3}
							type="text"
							{...register("animation_description")}
							placeholder={t("animation_description")}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>{t("animation_min_pers")}</FormLabel>
						<Input
							type="text"
							{...register("animation_min_pers")}
							placeholder={t("animation_min_pers")}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>{t("animation_max_pers")}</FormLabel>
						<Input
							type="text"
							{...register("animation_max_pers")}
							placeholder={t("animation_max_pers")}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>{t("animation_prep_time")}</FormLabel>
						<Input
							type="text"
							{...register("animation_prep_time")}
							placeholder={t("animation_prep_time")}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>{t("animation_duration")}</FormLabel>
						<Input
							type="text"
							{...register("animation_duration")}
							placeholder={t("animation_duration")}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>Tags</FormLabel>
						<TagsInput
							inputProps={{
								className: "react-tagsinput-input",
								placeholder: "Ajouter un tag",
							}}
							value={tags}
							onChange={(tag) =>
								setValue("animation_hashtags", tag)
							}
						/>
					</FormControl>
					<FormControl>
						<AddSimpleImage
							style={{
								maxWidth: "700px",
								width: "100%",
								height: "300px",
							}}
							label={t("animation_featured_image")}
							icon={<Image />}
							remove={() =>
								setValue("animation_featured_image", null)
							}
							action={(img) =>
								setValue(
									"animation_featured_image",
									img?.thumb || img?.full || img,
								)
							}
							image={watch("animation_featured_image")}
						/>
					</FormControl>
				</Box>

				<Box sx={{ mt: 2 }}>
					<Button disabled={saving} loading={saving} type="submit">
						{t("save")}
					</Button>
				</Box>
			</form>
		</>
	);
};

export default EditAnimationForm;
EditAnimationForm.propTypes = {};
EditAnimationForm.defaultProps = {};
