import React from "react";
import { FormSeparator } from "UI";

import {
	Typography,
	Radio,
	Input,
	RadioGroup,
	ListItemDecorator,
	List,
	ListItem,
} from "@mui/joy";
import { People, Euro } from "@mui/icons-material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const ProjectBudget = ({ control, setValue, watch }) => {
	const { t } = useTranslation();
	const {
		project_event_budget_type,
		project_event_budget,
		project_event_participants_qty,
	} = watch();

	const calcBuget = (budget, nbr, type) => {
		if (type === "per_person") {
			let r = 0;
			try {
				r = (parseFloat(budget) * parseInt(nbr)).toFixed(2);
				return r;
			} catch {
				return;
			}
		} else if (type === "total") {
			let r = 0;
			try {
				r = (parseFloat(budget) / parseInt(nbr)).toFixed(2);
				return r;
			} catch {
				return;
			}
		}

		return null;
	};

	return (
		<>
			<FormSeparator title="what_budget">
				<Controller
					name="project_event_budget"
					control={control}
					render={({ field: { value, onChange } }) => (
						<Input
							placeholder={t("budget")}
							value={value}
							onChange={onChange}
							type="number"
							sx={{ mb: 1 }}
						/>
					)}
				/>

				<Controller
					control={control}
					name="project_event_budget_type"
					rules={{ required: true }}
					render={({ field: value, onChange }) => (
						<RadioGroup
							aria-label="Your plan"
							onChange={(v) => {
								setValue(
									"project_event_budget_type",
									v.target.value,
								);
							}}
						>
							<List
								sx={{
									minWidth: 240,
									"--List-gap": "0.5rem",
									"--ListItem-paddingY": "1rem",
									"--ListItem-radius": "8px",
									"--ListItemDecorator-size": "32px",
								}}
							>
								{["per_person", "total"].map((item, index) => (
									<ListItem
										variant="outlined"
										key={item}
										sx={{
											boxShadow: "sm",
											bgcolor: "background.body",
										}}
									>
										<ListItemDecorator>
											{[<People />, <Euro />][index]}
										</ListItemDecorator>
										<Radio
											overlay
											checked={value.value === item}
											value={item}
											label={t(item)}
											sx={{
												flexGrow: 1,
												flexDirection: "row-reverse",
											}}
											slotProps={{
												action: ({ checked }) => ({
													sx: (theme) => ({
														...(checked && {
															inset: -1,
															border: "2px solid",
															borderColor:
																theme.vars
																	.palette
																	.primary[500],
														}),
													}),
												}),
											}}
										/>
									</ListItem>
								))}
							</List>
						</RadioGroup>
					)}
				/>
				{project_event_budget && project_event_participants_qty && (
					<Typography>
						{project_event_budget_type === "per_person" && (
							<>
								<span>
									{t("budget_total", {
										price: calcBuget(
											project_event_budget,
											project_event_participants_qty,
											"per_person",
										),
									})}{" "}
								</span>
							</>
						)}
						{project_event_budget_type === "total" && (
							<>
								<span>
									{t("budget_person", {
										price: calcBuget(
											project_event_budget,
											project_event_participants_qty,
											"total",
										),
									})}
								</span>
							</>
						)}
					</Typography>
				)}
			</FormSeparator>
		</>
	);
};

export default ProjectBudget;
