import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { PlacesStoreContainer } from "../../PlacesStore";
import { PlacesSearchForm } from "forms/placesSearch";
import { AlgoliaContainer } from "Components/algolia";
import { Results, PlaceMap } from "./partials";
import { Pagination } from "react-instantsearch";
const PlaceSearch = ({
	adminSearch,
	projectId,
	projectPlaces,
	onEnd,
	fromCatalog,
}) => {
	const { t } = useTranslation();

	const [showMap, setShowMap] = React.useState(false);
	const [mapCenter, setMapCenter] = React.useState({
		lat: 48.8566,
		lng: 2.3522,
	});

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<AlgoliaContainer
				index="places"
				filters={!adminSearch && "publish:true"}
			>
				<PlacesSearchForm
					showMap={showMap}
					setShowMap={setShowMap}
					setMapCenter={setMapCenter}
				/>
				<Box sx={{ display: "flex", flexGrow: 1 }}>
					<Box
						className="left_height"
						sx={{
							width: !showMap ? "100%" : "50%",
							background: "#fff",
							height: "60vh",
							overflow: "auto",
						}}
					>
						<Results
							projectId={projectId}
							projectPlaces={projectPlaces}
							onEnd={onEnd}
							showMap={showMap}
							fromCatalog={fromCatalog}
						/>
					</Box>
					{showMap && (
						<Box sx={{ flexGrow: 1 }}>
							<PlaceMap mapCenter={mapCenter} />
						</Box>
					)}
				</Box>
				<Box
					sx={{
						background: "#F1F1F1",
						display: "flex",
						justifyContent: "center",
						padding: "12px",
						a: {
							fontSize: "18px",
						},
					}}
				>
					<Pagination />
				</Box>
			</AlgoliaContainer>
		</Box>
	);
};

export default PlacesStoreContainer(PlaceSearch);
PlaceSearch.propTypes = {};
PlaceSearch.defaultProps = {};
