import React, { useState, useEffect } from "react";
import {
	Box,
	ListItemDecorator,
	ListItemContent,
	Button,
	ListItemButton,
	ListItem,
	Divider,
	List,
} from "@mui/joy";
import { Logo } from "./partials";
import {
	Home,
	KeyboardArrowRight,
	KeyboardArrowDown,
	VisibilityOutlined,
	Dashboard,
	InfoOutlined,
	Surfing,
	QrCode,
	Brush,
	DinnerDining,
	ImportContacts,
	LogoutOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import Helpers from "../../../../../utils/Helpers";
import useAuthProfile from "../../../../../containers/authentification/hooks/useAuthProfile";

const ProjectMenu = ({ style, openMenu, setOpenMenu, id }) => {
	const { logout } = useAuthProfile();
	const { t } = useTranslation();
	const location = useLocation();
	const rootLocation = Helpers.uriParts(location.pathname, 0);

	const [items, setItems] = useState([
		{
			label: "dashboard",
			Icon: Dashboard,
			path: `/projects/${id}/dashboard`,
		},
	]);

	const subItems = [
		{
			label: "out_editor",
			path: "/projects",
			Icon: LogoutOutlined,
			color: "warning",
		},
	];

	const toggleOpen = (index) => {
		setItems((prevItems) =>
			prevItems.map((item, i) =>
				i === index ? { ...item, open: !item.open } : item,
			),
		);
	};

	return (
		<Box style={style} sx={{ background: "white" }}>
			<Logo />
			<List sx={{ p: 2, maxHeight: "52%", overflow: "auto" }}>
				{items.map((item, index) => (
					<ListItem
						key={index}
						sx={{
							flexDirection: "column",
							alignItems: "flex-start",
						}}
					>
						<ListItemButton
							component={NavLink}
							to={item.path}
							selected={location.pathname === item.path}
							onClick={() => toggleOpen(index)}
							sx={{
								width: "100%",
								color: item.open ? "primary.main" : "inherit",
							}}
						>
							<ListItemDecorator>
								{item.Icon && <item.Icon />}
							</ListItemDecorator>
							<ListItemContent>{t(item.label)}</ListItemContent>
							{item.open ? (
								<KeyboardArrowDown />
							) : (
								<KeyboardArrowRight />
							)}
						</ListItemButton>
						{item.childrens && item.open && (
							<List sx={{ pl: 4 }}>
								{item.childrens.map((child, childIndex) => (
									<ListItem key={childIndex}>
										<ListItemButton
											component={NavLink}
											to={child.path}
											selected={
												location.pathname === child.path
											}
										>
											<ListItemContent>
												{t(child.label)}
											</ListItemContent>
										</ListItemButton>
									</ListItem>
								))}
							</List>
						)}
					</ListItem>
				))}
			</List>
			<Divider sx={{ my: 2 }} />
			<List sx={{ p: 2 }}>
				{subItems.map((item, index) => (
					<ListItem
						key={index}
						sx={{
							flexDirection: "column",
							alignItems: "flex-start",
						}}
					>
						<Button
							color={item.color}
							component={NavLink}
							to={item.path}
							sx={{ width: "100%" }}
						>
							<ListItemDecorator>
								{item.Icon && <item.Icon />}
							</ListItemDecorator>
							<ListItemContent>{t(item.label)}</ListItemContent>
						</Button>
					</ListItem>
				))}
			</List>
		</Box>
	);
};

export default ProjectMenu;
