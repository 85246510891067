import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { TeamBuildingsList } from "./views";

const TeamBuildings = (props) => {
	const { t } = useTranslation();

	return (
		<Switch>
			<Route exact path="/admin/team-buildings">
				<TeamBuildingsList />
			</Route>
		</Switch>
	);
};

export default TeamBuildings;
TeamBuildings.propTypes = {};
TeamBuildings.defaultProps = {};
