import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import { Add } from "@mui/icons-material";
import PropTypes from "prop-types";
import { BOLayout } from "../../../../components/layouts";
import Project from "models/projects";
import { useParams } from "react-router-dom";
import StickyBox from "react-sticky-box";

import {
	SelectedPlaces,
	ProjectBrief,
	AddAProposition,
	EventPrograms,
	ProjectHeaderSection,
	AnimationsList,
	UpdateStatusModal,
	TeamBuildingsList,
	TeamBuildingsModal,
	SharedDocuments,
	BriefModalEdit,
	AnimationsModal,
	AddDocumentModal,
} from "./partials";
import { WhiteContainer, WhiteContainerButton } from "UI";

const ProjectEntity = new Project();

const ProjectDashboard = (props) => {
	const { t } = useTranslation();
	let { projectID } = useParams();
	const [datas, setDatas] = React.useState(null);
	const [openBriefModal, setOpenBriefModal] = React.useState(false);
	const [openStatusModal, setOpenStatusModal] = React.useState(false);
	const [openSearchAnimationModal, setOpenSearchAnimationModal] =
		React.useState(false);
	const [openSearchTeamBuildingModal, setOpenSearchTeamBuildingModal] =
		React.useState(false);
	const [openPropositionModal, setOpenPropositionModal] =
		React.useState(false);

	const [openAddDocumentModal, setOpenAddDocumentModal] =
		React.useState(false);

	const fetchProject = async () => {
		try {
			const project = await ProjectEntity.getProject(projectID);
			if (project) {
				setDatas(project);
			}
		} catch (e) {
			console.error("fetching project error", e.message);
		}
	};

	React.useEffect(() => {
		fetchProject();
	}, [projectID]);
	return (
		<>
			<BOLayout
				documentID={projectID}
				isDocumentStatusMonitoring
				showProjectMenu
				title="project_dashboard"
			>
				<Box>
					<ProjectHeaderSection
						openPropositionModal={() =>
							setOpenPropositionModal(true)
						}
						openStatus={() => setOpenStatusModal(true)}
						{...datas}
					/>
				</Box>
				<Box
					sx={{ display: "flex", flexDirection: "row", columnGap: 2 }}
				>
					<Box sx={{ flexGrow: 1 }}>
						<SelectedPlaces
							setDatas={(d) => setDatas({ ...datas, ...d })}
							places={datas?.project_places || []}
						/>
					</Box>
					<Box
						sx={{
							width: "320px",
						}}
					>
						<StickyBox
							component={Box}
							offsetTop={100}
							offsetBottom={20}
						>
							<Box
								sx={{
									background: "white",
									borderRadius: 10,
									p: 1,
								}}
							>
								<ProjectBrief
									{...datas}
									handleOpenBriefModal={() =>
										setOpenBriefModal(true)
									}
								/>
							</Box>
						</StickyBox>
					</Box>
				</Box>

				<WhiteContainer style={{ mt: 2 }} title={t("animations")}>
					<AnimationsList
						openSearch={() => setOpenSearchAnimationModal(true)}
						setDatas={(d) => setDatas(d)}
						datas={datas}
						project_id={projectID}
					/>
				</WhiteContainer>
				<WhiteContainer style={{ mt: 2 }} title={t("team_buildings")}>
					<TeamBuildingsList
						openSearch={() => setOpenSearchTeamBuildingModal(true)}
						setDatas={(d) => setDatas(d)}
						datas={datas}
						project_id={projectID}
					/>
				</WhiteContainer>
				<WhiteContainerButton
					title={t("my_documents")}
					action={
						<Button
							onClick={() => setOpenAddDocumentModal(true)}
							color="primary"
							variant="solid"
							type="button"
						>
							<Add /> {t("add_document")}
						</Button>
					}
				>
					<SharedDocuments
						setDatas={(d) => setDatas({ ...datas, ...d })}
						datas={datas}
					/>
				</WhiteContainerButton>
				<WhiteContainer style={{ mt: 2 }} title={t("event_program")}>
					<EventPrograms
						setDatas={(d) => setDatas({ ...datas, ...d })}
						datas={datas}
						project_id={projectID}
					/>
				</WhiteContainer>

				<UpdateStatusModal
					id={datas?.id}
					setDatas={(d) => setDatas({ ...datas, ...d })}
					status={datas?.status}
					open={openStatusModal}
					close={() => setOpenStatusModal(false)}
				/>
				<AnimationsModal
					datas={datas}
					open={openSearchAnimationModal}
					setDatas={(d) => setDatas(d)}
					close={() => setOpenSearchAnimationModal(false)}
				/>
				<TeamBuildingsModal
					datas={datas}
					open={openSearchTeamBuildingModal}
					setDatas={(d) => setDatas(d)}
					close={() => setOpenSearchTeamBuildingModal(false)}
				/>
				<AddAProposition
					datas={datas}
					setDatas={(d) => setDatas({ ...datas, ...d })}
					open={openPropositionModal}
					close={() => setOpenPropositionModal(false)}
				/>
				<AddDocumentModal
					datas={datas}
					setDatas={(d) => setDatas({ ...datas, ...d })}
					open={openAddDocumentModal}
					handleClose={() => setOpenAddDocumentModal(false)}
				/>
				<BriefModalEdit
					briefDatas={{
						project_event_date_period:
							datas?.project_event_date_period,
						project_event_day_duration:
							datas?.project_event_day_duration,
						project_name: datas?.project_name,
						project_event_participants_qty:
							datas?.project_event_participants_qty,
						event_budget: datas?.event_budget,
						event_budget_type: datas?.event_budget_type,
						project_event_type: datas?.project_event_type,
						project_details: datas?.project_details,
						project_event_budget: datas?.project_event_budget,
						project_event_budget_type:
							datas?.project_event_budget_type,
						project_event_objective: datas?.project_event_objective,
						project_event_date_stopped:
							datas?.project_event_date_stopped,
						project_event_start_date:
							datas?.project_event_start_date,
						project_event_end_date: datas?.project_event_end_date,
						project_event_seminar_type:
							datas?.project_event_seminar_type,
					}}
					open={openBriefModal}
					setDatas={(d) => setDatas({ ...datas, ...d })}
					close={() => setOpenBriefModal(false)}
				/>
			</BOLayout>
		</>
	);
};

export default ProjectDashboard;
ProjectDashboard.propTypes = {};
ProjectDashboard.defaultProps = {};
