import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, IconButton } from "@mui/joy";
import PropTypes from "prop-types";
import { LocationOn, Delete } from "@mui/icons-material";
import { useHistory, Link } from "react-router-dom";

const PlaceItem = ({
	id,
	featured_image,
	nom,
	pays,
	type_lieu,
	ville,
	code_postal,
	region,
	removePlace,
}) => {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<Box
			sx={{
				background: "#FFFFFF",
				borderRadius: "15px",
				display: "flex",
				flexDirection: "row",
				columnGap: 2,
			}}
		>
			<Box
				sx={{
					width: "200px",
					borderRadius: "15px 0px 0px 15px",
					height: "150px",
					background: `url("${featured_image}") no-repeat  center center  / cover`,
				}}
			></Box>
			<Box sx={{ flexGrow: 1 }}>
				<Box sx={{ p: 2, width: "100%" }}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							width: "100%",
							justifyContent: "space-between",
						}}
					>
						<Typography
							component={Link}
							to={`/places/view/${id}`}
							sx={{
								fontSize: "22px",
								"&:hover": {
									opacity: 0.7,
								},
								cursor: "pointer",
							}}
							color="primary"
						>
							{nom}
						</Typography>
						<IconButton onClick={removePlace} color="primary">
							<Delete />
						</IconButton>
					</Box>
					<Typography>
						<LocationOn /> {region},{code_postal}-{pays}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default PlaceItem;
PlaceItem.propTypes = {};
PlaceItem.defaultProps = {
	featured_image: "https://picsum.photos/500/300",
};
