import React from "react";
import { Switch, Route, redirect } from "react-router-dom";
import {
	DashboardAdmin,
	Users,
	Events,
	Places,
	Requests,
	Groups,
	MassPlaceImport,
	Projects,
	TeamBuildings,
	Animations,
	Notifications,
} from "./views";

const AdminRoutes = (props) => {
	return (
		<Switch>
			<Route exact path="/admin">
				<DashboardAdmin />
			</Route>
			<Route path="/admin/dashboard">
				<DashboardAdmin />
			</Route>
			<Route path="/admin/projects">
				<Projects />
			</Route>
			<Route path="/admin/animations">
				<Animations />
			</Route>
			<Route path="/admin/team-buildings">
				<TeamBuildings />
			</Route>
			<Route path="/admin/users">
				<Users />
			</Route>
			<Route path="/admin/places">
				<Places />
			</Route>
			<Route path="/admin/events">
				<Events />
			</Route>
			<Route path="/admin/requests">
				<Requests />
			</Route>
			<Route path="/admin/groups">
				<Groups />
			</Route>
			<Route path="/admin/notifications">
				<Notifications />
			</Route>
			<Route path="/admin/mass-place-import">
				<MassPlaceImport />
			</Route>
		</Switch>
	);
};

export default AdminRoutes;
